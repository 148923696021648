import { AxiosPromise } from 'axios';

import { request } from '../../baserequest';
import { paginatedresult } from '../../../types/pagination';
import { item, ItemFavourite } from './types';


const APIURL = process.env.REACT_APP_SKALO_API;


const ItemsList = ( 
  limit: number, 
  offset: number, 
  query: string | '', 
  system_number:string = '', 
  categoryId:number | undefined = undefined,
  category__catalog__id:number | undefined = undefined,
): AxiosPromise<paginatedresult<item>>=> {
  return request({
    options:{
      url: `${APIURL}itemslist/`,
      method: 'GET',
      params:{
        limit: limit,
        offset: offset,
        ...((query !== '') && 
        { name: query }
        ),
        ...((system_number !== '') && 
        { system_number: system_number }
        ),
        ...((categoryId !== undefined) && 
        { category__id: categoryId }
        ),
        ...((category__catalog__id !== undefined) && 
        { category__catalog__id: category__catalog__id }
        ),
      },
    },
    authenticate:true,
  }).then((response)=>({
    ...response,
    data: {
      ...response.data,
      results: response.data.results,
    },
  }));
};

const FavouriteItemsList = ( 
  limit: number, 
  offset: number, 
  companyId?:number,
  itemqs: string = ''
): AxiosPromise<paginatedresult<ItemFavourite>>=> {
  return request({
    options:{
      url: `${APIURL}mostChosenItemsList/`,
      method: 'GET',
      params:{
        limit: limit,
        offset: offset,
        companyId: companyId !== undefined ? companyId : null,
        ...((itemqs !== '') && 
        { itemqs: itemqs }
        ),
      },
    },
    authenticate:true,
  }).then((response)=>({
    ...response,
    data: { 
      ...response.data,
      results: response.data.results,
    },
  }));
};


export const ItemQuery = { ItemsList, FavouriteItemsList };