import React, { Fragment, useContext } from 'react';

import { UserContext } from '../UserContext/UserContext';


export const Authenticated: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const { isLoggedIn } = useContext(UserContext);

  if (isLoggedIn) {
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }

  return null;
};

export const NotAuthenticated: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const { isLoggedIn } = useContext(UserContext);

  if (!isLoggedIn) {
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }

  return null;
};
