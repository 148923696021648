import React from 'react';
import { Box, Toolbar, Button, styled } from '@mui/material';
import { Link } from 'react-router-dom';

import { MediaQueryContext } from '../../../contexts';


export const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: 'inherit',
});

export const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '5px',
  marginBottom: '5px',
});

export const UserBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
});