import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { pl } from 'date-fns/locale';

import {
  LayoutContextProvider,
  UserContextProvider,
  LocalizationContextProvider,
  PermissionContextProvider,
  CompanyContextProvider,
  DialogContextProvider,
  OrderContextProvider,
  MediaQueryContextProvider,
} from './contexts';
import { Root } from './views/Root';
import theme from './layouts/theme/theme';
import { SwitchProvider } from './hooks/SwitchContext';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 360000, // 6 minutes
    },
  },
});

export const AppWithTheme = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <SwitchProvider>
        <Root/>
      </SwitchProvider>
    </ThemeProvider>
  );
};

function App() {
  return (
    <Router>
      <LocalizationProvider locale={pl} dateAdapter={AdapterDateFns}>
        <QueryClientProvider client={queryClient}>
          <LocalizationContextProvider>
            <PermissionContextProvider>
              <UserContextProvider>
                <CompanyContextProvider>
                  <LayoutContextProvider>
                    <OrderContextProvider>
                      <DialogContextProvider>                      
                        <MediaQueryContextProvider>
                          <AppWithTheme/>
                        </MediaQueryContextProvider>                      
                      </DialogContextProvider>
                    </OrderContextProvider>
                  </LayoutContextProvider>
                </CompanyContextProvider>
              </UserContextProvider>
            </PermissionContextProvider>
          </LocalizationContextProvider>
        </QueryClientProvider>
      </LocalizationProvider>
    </Router>
  );
}

export default App;
