import { useQuery } from '@tanstack/react-query';

import { categoryQuery } from '../clients/catalogs/category/category';
import { cacheKeys } from '../config';


export const useCategoryList = () => {
  const { data: { data }={}, isLoading } = useQuery([cacheKeys.category.categoryList], 
    ()=>categoryQuery.CategoryList(),
    {
      enabled: true,
    },);

  return ({
    categoryList:data?.results,
    isLoadingCategory: isLoading,
  });
};