import React, { FC, ReactNode, createContext, useState, useEffect } from 'react';

import { dictionary as pl } from './languages/pl';
import { ContextProps, SupportedLanguage } from './types';


const defaultContext: ContextProps = {
  language: SupportedLanguage.Polish,
  dictionary: pl,
  setLanguage: () => null,
};


const getLanguageFromLocalStorage = (): SupportedLanguage => {
  const lang = localStorage.getItem('ar-language') as SupportedLanguage || defaultContext.language;
  if(['pl'].includes(lang)) {
    return lang;
  }
  return SupportedLanguage.Polish;
};


export const LocalizationContext = createContext(defaultContext);


export const LocalizationContextProvider: FC<{ children?: ReactNode }> = ({ children }) => {

  const [language, setLanguage] = useState<SupportedLanguage>(getLanguageFromLocalStorage());
  const dictionaries = { pl };
  const dictionary = dictionaries[language as SupportedLanguage];


  useEffect(() => {
    localStorage.setItem('addall-language', language);
  },[language]);

  return (
    <LocalizationContext.Provider
      value={{
        dictionary,
        language,
        setLanguage,
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};

