import queryString from 'query-string';
import axiosRequest, { AxiosRequestConfig, AxiosPromise } from 'axios';


export type AbstractRequest = <T = any, D = any>(props: { options: AxiosRequestConfig<D>; authenticate?: boolean; maxRetries?: number; }) => AxiosPromise<T>;


const serializeParams = (params: any) => queryString.stringify(params);

const attachOptions = (options: AxiosRequestConfig, authenticate = true) => {
  const token = ():string | undefined => {
    const token = localStorage.getItem('JWT');
    if (token) {
      return token;
    } else {
      return undefined;
    }
  };

  const selectedLanguage = typeof localStorage !== 'undefined' ? localStorage.getItem('skalo-language') || 'pl' : 'pl';
  
  return {
    ...options,
    paramsSerializer: (params: any) => serializeParams(params),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': selectedLanguage,
      'Cache-Control': 'max-age=0',
      ...(authenticate && {
        'Authorization': token(),
      }),
      ...options.headers,
    },
  };
};

export const request: AbstractRequest = async ({ options, authenticate = true, maxRetries = 3 }) => {

  const optionsWithHeader = attachOptions(options, authenticate);
  return axiosRequest(optionsWithHeader).catch((e) => {
    if(e.response && e.response.status && (e.response.status === 403 || e.response.status === 401) && maxRetries > 0) {
      if([401, 403].includes(e.response.status)) {
        localStorage.removeItem('JWT');
      }
    }
    throw(e);
  });
};
