import React, { FC, useContext, useState, useCallback, useMemo } from 'react';
import { DataGridPro, 
  GridColDef,
  GridRenderCellParams,
  GridActionsCellItem, 
  GridValueGetterParams } from '@mui/x-data-grid-pro';
import { Delete, Done, Add, Remove } from '@mui/icons-material';
import { Typography, Box, Button } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { sum } from 'lodash';

import { DialogContext, LayoutContext, OrderContext } from '../../../../contexts';
import { CustomNoRowsOverlay } from './utilities/NoRowsOverlay';
import NoPhoto from '../../../../layouts/images/noImage.png';
import { OrderQuery } from '../../../../clients/orders/orders';


const CustomFooterStatusComponent = () => {
  const { orderItems, order, refetchOrder } = useContext(OrderContext);
  const { setOrderDialogState } = useContext(DialogContext);
  const { changesSaved, genericError } = useContext(LayoutContext);
  const im = orderItems!.map((e)=>parseFloat(e.price_netto!) * parseFloat(e.quanity));
  const sumOfOrder = sum(im);

  const handleUpdateOrder = useMutation({ 
    mutationFn: () => OrderQuery.UpdateOrder(order!.id, { confirmed:true }),
  });


  return (
    <Box sx={{ p: 1, display: 'flex', justifyContent:'space-between', alignItems:'center' }}>
      <Typography>
        Łączna kwota zamówienia: {parseFloat((sumOfOrder).toFixed(2))}zł
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        sx={{ m: 2, color: 'white' }}
        startIcon={<Done/>}
        onClick={async()=>{
          const response = await handleUpdateOrder.mutateAsync();
          if (response.status === 200) {
            changesSaved('Złożono zamówienie');
            setOrderDialogState(true);
            refetchOrder();
          } else {
            genericError();
          }
        }}
      >
        Złóż zamówienie
      </Button>
    </Box>
  );
};


export const OrderGrid:FC = () => {
  const { orderItems, refetchOrderItems } = useContext(OrderContext);
  const { changesSaved, genericError } = useContext(LayoutContext);


  const handleAddProduct = useMutation({ 
    mutationFn: ( param:any ) => OrderQuery.UpdateOrderItem(param.id, { quanity:param.quanity+1 }),
  });

  
  const  handleRemoveProduct = useMutation({ 
    mutationFn: ( param:any ) => OrderQuery.UpdateOrderItem(param.id, { quanity:param.quanity-1 }),
  });
  
  const handleDeleteProduct = useMutation({ 
    mutationFn: OrderQuery.DeleteOrderItem,
  });


  const columns: GridColDef[] = [
    {
      field: 'lp',
      headerName: 'LP',
      width: 80,
      sortable: false,
      filterable: false,
      renderCell: (params) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
    },
    {
      field: 'image',
      width: 120,
      headerName: '',
      sortable:false,
      filterable:false,
      align:'center',
      renderCell: (params: GridRenderCellParams<{item:{image?:string}}>) => (
        <img height="50px" src={params.row.item?.image || NoPhoto}/>
      ),
    },
    {
      field: 'name',
      width: 410,
      headerName: 'Nazwa produktu',
      sortable:false,
      filterable:false,
      valueGetter: ({ row }) => {
        return `${row.item.name} szt`;
      },
    },
    {
      field: 'quanity',
      width: 100,
      headerName: 'Ilość',
      sortable:false,
      description:
                'Ilość danego produktu',
      valueGetter: ({ row }) => {
        return `${row.quanity} szt`;
      },
    },
    {
      field: 'price_netto',
      width: 180,
      headerName: 'Cena Netto',
      description:
                'Cena Produktu',
      valueGetter: ({ row }) => {
        return `${row.price_netto || 0.00} zł`;  
      },              
    },
    {
      field: 'sum',
      width: 180,
      headerName: 'Suma',
      description:
                  'Cena Produktu',
      valueGetter: ({ row }) => {
        return `${parseFloat(row.price_netto) * parseFloat(row.quanity) || 0.00} zł`;  
      },              
    },
    {
      field: 'actions',
      type: 'actions',
      width: 180,
      getActions: (params) => [
        <GridActionsCellItem onClick={async () => {
          const response = await handleAddProduct.mutateAsync({ id:params.id, quanity:parseFloat(params.row.quanity) });
          if (response.status === 200) {
            refetchOrderItems();
            changesSaved('Zmiany zostały zapisane');
          } else {
            genericError();
          }
        }} icon={<Add/>} label="Add"/>,
        <GridActionsCellItem onClick={async () => {
          const response = await handleRemoveProduct.mutateAsync({ id:params.id, quanity:parseFloat(params.row.quanity) });
          if (response.status === 200) {
            refetchOrderItems();
            changesSaved('Zmiany zostały zapisane');
          } else {
            genericError();
          }
        }} icon={<Remove/>} label="Remove"/>,
        <GridActionsCellItem onClick={async () => {
          const response = await handleDeleteProduct.mutateAsync(params.id);
          if (response.status === 204) {
            refetchOrderItems();
            changesSaved('Usunięto produkt z zamówienia');
          } else {
            genericError();
          }
        }} icon={<Delete/>} label="Delete"/>,
      ],
    },
  ];
  

  const Grid = useMemo(()=>{

    return (
      <Box sx={{ width: '100%' }}>
        <DataGridPro
          autoHeight
          columns={columns}
          rows={orderItems || []}
          slots={{
            footer: CustomFooterStatusComponent,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          disableRowSelectionOnClick
          disableMultipleRowSelection
          disableColumnPinning
          rowCount={orderItems?.length || 0}
          localeText={{
            footerTotalRows:'Łączna liczba pozycji',
            columnHeaderFiltersTooltipActive: (count) =>
              count !== 1 ? `${count} aktywnych filtrów` : `${count} aktywny filtr`,
            columnHeaderFiltersLabel: 'Pokaż Filtry',
            columnHeaderSortIconLabel: 'Sortuj',
            columnMenuShowColumns: 'Pokaż kolumny',
            columnMenuManageColumns: 'Zarządzaj kolumnami',
            columnMenuFilter: 'Filtruj',
            columnMenuHideColumn: 'Chowaj kolumny',
            columnMenuUnsort: 'Odsortuj',
            toolbarDensity: 'Gęstość',
            toolbarDensityLabel: 'Gęsto',
            toolbarDensityCompact: 'Kompaktowo',
            toolbarDensityStandard: 'Standardowo',
            toolbarDensityComfortable: 'Komfortowo',
            toolbarFilters: 'Filtry',
            toolbarFiltersLabel: 'Pokaż Filtry',
            toolbarFiltersTooltipHide: 'Chowaj Filtry',
            toolbarFiltersTooltipShow: 'Pokaż Filtry',
            toolbarFiltersTooltipActive: (count) =>
              count !== 1 ? `${count} aktywnych Filtrów` : `${count} aktywny filter`,
            toolbarExport: 'Exportuj',
            toolbarExportLabel: 'Exportuj',
            toolbarExportCSV: 'Pobierz jako CSV',
            toolbarExportPrint: 'Wydrukuj',
            filterPanelAddFilter: 'Dodaj filter',
            filterPanelRemoveAll: 'Usuń wszystkie',
            filterPanelDeleteIconLabel: 'Usuń',
            filterPanelLogicOperator: 'Operator Logiczny',
            filterPanelOperator: 'Operator',
            filterPanelOperatorAnd: 'I',
            filterPanelOperatorOr: 'Lub',
            filterPanelColumns: 'Kolumny',
            filterPanelInputLabel: 'Wartość',
            filterPanelInputPlaceholder: 'Wartość filtrowana',
            noRowsLabel: 'Brak wierszy',
            noResultsOverlayLabel: 'Żadnych wyników wyszukiwań',
            columnsPanelTextFieldLabel: 'Znajdź Kolumne',
            columnsPanelTextFieldPlaceholder: 'Tytuł kolumny',
            columnsPanelDragIconLabel: 'Reorder column',
            columnsPanelShowAllButton: 'Pokaż wszystkie',
            columnsPanelHideAllButton: 'Ukryj wszystkie',
          }}
        />
      </Box>
    );
  }, [orderItems]);
  


  return (
    <>
      {Grid}
    </>
  );

};
