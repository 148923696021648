import { AxiosPromise } from 'axios';

import { request } from '../baserequest';
import { paginatedresult } from '../../types/pagination';
import { Company } from './types';


const baseurl = process.env.REACT_APP_SKALO_API;


const CompanyList = (limit:number, offset:number, params: any):AxiosPromise<paginatedresult<Company>> => {
  return request({
    options:{
      url: `${baseurl}company/`,
      method:'GET',
      params:{
        limit: limit,
        offset: offset,
        ...params,
      },
    },
  }).then((response)=>({
    ...response,
    data:{
      ...response.data,
      results:response.data.results,
    },
  }));
};


const ToggleCompanyActive = (id: number, data: Partial<Company>): AxiosPromise => {
  return request({
    options: {
      url: `${baseurl}company/${id}/`,
      method: 'PUT',
      data: {
        ...data,
      },    
    },
  });
};



export const CompanyQuery = { CompanyList, ToggleCompanyActive };