import React, { createContext, FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { useMe } from '../../hooks/useMe';
import { UserClient } from '../../clients/user/user';
import { ContextProps } from './types';
import { routes } from '../../config';


export const defaultContext: ContextProps = {
  user: undefined,
  role: 'client',
  isAdmin: false,
  setJWT: ()=> {},
  login: () => {},
  logout: () => {},
  register: ()=> {},
  isLoggedIn: false,
  Loading: false,
  error: undefined,
  JWT: undefined,
};

export const UserContext = createContext(defaultContext);

export const UserContextProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();

  const [JWT, setJWT] = useState<string | undefined>(undefined);

  const { fetchStatus, user, isLoading, error } = useMe(JWT!, JWT, {
    enabled: !!JWT,
  });

  const Loading = fetchStatus == 'idle' ? false : isLoading;

  const role = user?.role;

  const queryClient = useQueryClient();

  const isLoggedIn = useMemo(() => !!user, [user]);

  const isAdmin = useMemo(() => !!user?.is_superuser, [user]);

  useEffect(()=>{
    const jwt_token = localStorage.getItem('JWT');
    if (!!jwt_token) {
      setJWT(jwt_token);
    } else {
      if (JWT !== undefined) {
        localStorage.setItem('JWT', JWT);
      }
    }
  }, [JWT]);

  const login = useCallback( async ( data:any ) => {
    const response = await UserClient.loginUser(data);
    if ('jwt' in response.data) {
      
      if (response.data.redirect) {
        window.location.href = response.data.to;
      } else {
        localStorage.setItem('JWT', response.data.jwt);
        setJWT(response.data.jwt);
        navigate(routes.home);
      }
    } else {
      return response.data;
    }
  }, [JWT]);

  const register = useCallback( async (username:string, 
    email:string, 
    password:string, 
    first_name:string, 
    last_name:string, 
    phone:string,) => {
    const response = await UserClient.registerUser(username, email, password, first_name, last_name, phone);
    localStorage.setItem('JWT', response.data.jwt);
    setJWT(response.data.jwt);
  },[JWT]);

  const logout = useCallback(() => {
    localStorage.removeItem('JWT');
    setJWT(undefined);
    navigate(routes.login);
  }, [JWT]);
  
  return (
    <UserContext.Provider
      value={{
        user,
        JWT,
        setJWT,
        isAdmin,
        role,
        logout,
        login,
        register,
        isLoggedIn,
        Loading,
        error,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
