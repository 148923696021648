import React, { FC, useContext, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Divider,
  Pagination,
  Stack,
} from '@mui/material';

import noImage from '../../../layouts/images/noImage.png';
import ItemsRender from './components/ItemsRender';
import Loader from '../../../layouts/components/BodyLoading/Loader';
import SearchingError from '../Catalogs/catalogs/Search/SearchingError';
import { useFavoruiteItemsList } from '../../../hooks/useFavouriteItemsList';
import { CompanyContext, LayoutContext } from '../../../contexts';
import SearchInput from '../Catalogs/catalogs/Search/Search';


export const FavouriteItems: FC = () => {
  const [query, setQuery] = useState('');
  const [ currentPage, setCurrentPage ] = useState(1);
  const { company } = useContext(CompanyContext);
  const limit: number = 20;

  const { isError, isLoading, itemsList, count } = useFavoruiteItemsList(
    (currentPage - 1) * limit,
    limit,
    query
  );
  useEffect(()=>{
    setCurrentPage(1);
  }, [company]);

  const handleChange = (value: string) => {
    setQuery(value);
    setCurrentPage(1);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };
  
  if (company === undefined) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Typography variant="h3" color="text.primary">
          Wybierz Profil Restauracji
        </Typography>
      </Box>
    );
  }
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h3" color="text.primary" sx={{ m: 2 }}>
          Najczęściej zamawiane
          </Typography>
        </Box>
        <Divider>
          <Typography variant="h4" color="text.primary" sx={{ m: 2 }}>
            {company?.name}
          </Typography>
          <SearchInput query={query} handleChang={handleChange}/>
        </Divider>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', px: 2, pt: 2 }}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', m: 2, width: '100%',
            marginLeft: '100px',
            marginRight: '100px' }}
        >
          {isLoading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '80vh',
                width: '100%',
              }}
            >
              <Loader/>
            </Box>
          )}
          <Stack
            direction="column"
            justifyContent="center"
            spacing={2}
            width="100%"
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'stretch',
                gap: '20px',
                pt: 2,
              }}
            >
              {!isLoading &&
                itemsList.map((item) => {
                  return (
                    <ItemsRender
                      key={item.item.id}
                      id={item.item.id}
                      image={item.item.image ? item.item.image : noImage}
                      alt={item.item.name}
                      title={item.item.name}
                      //price={item.item.price}
                      quantity={item.item.quantity}
                      count={item.count}                     
                    />
                  );
                })}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '20px 0px',
              }}
            >
              {!isLoading && count !== 0 ? (
                <Pagination
                  count={Math.ceil(count / limit)}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              ) : (
                !isLoading && <SearchingError/>
              )}
            </Box>
          </Stack>
          
        </Box>
      </Box>
    </>
  );
};