import React, { FC, useContext, useState, useCallback, useMemo } from 'react';
import { DataGridPro, 
  GridColDef,
  GridRenderCellParams,
  GridActionsCellItem,
  GridFilterModel,
  GridToolbar,
  GridSortModel } from '@mui/x-data-grid-pro';
import { Delete,  ExpandMore, ExpandLess } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';
import { Typography, Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { UserContext } from '../../../../contexts';
import { Offer } from '../../../../clients/offers/types';
import { FavoriteCell } from './OfferCells/OfferFavouriteCell';
import { OffersQuery } from '../../../../clients/offers/offers';
import { LayoutContext } from '../../../../contexts';
import { useOfferList } from '../../../../hooks/offers/useOffersList';
import { PageSize } from '../../../../config';
import { calculateColor } from './OfferCells/OfferMarginCell';
import { NameOfferOnlyOperators } from './OffersFilters/NameFilter';
import { NumberOfferOnlyOperators } from './OffersFilters/NumberFilter';
import { DateOfferOnlyOperators } from './OffersFilters/DateFilter';



const rootStyles = {
  width: '100%',
  '& .Green100': {
    backgroundColor: green[100],
  },
  '& .Green200': {
    backgroundColor: green[200],
  },
  '& .Green300': {
    backgroundColor: green[300],
  },
  '& .Green400': {
    backgroundColor: green[400],
  },
  '& .Green500': {
    backgroundColor: green[500],
  },
  '& .Green600': {
    backgroundColor: green[600],
  },
  '& .Green700': {
    backgroundColor: green[700],
  },
  '& .Green800': {
    backgroundColor: green[800],
  },
  '& .Green900': {
    backgroundColor: green[900],
  },
  '& .Red100': {
    backgroundColor: red[100],
  },
  '& .Red200': {
    backgroundColor: red[200],
  },
  '& .Red300': {
    backgroundColor: red[300],
  },
  '& .Red400': {
    backgroundColor: red[400],
  },
  '& .Red500': {
    backgroundColor: red[500],
  },
  '& .Red600': {
    backgroundColor: red[600],
  },
  '& .Red700': {
    backgroundColor: red[700],
  },
  '& .Red800': {
    backgroundColor: red[800],
  },
  '& .Red900': {
    backgroundColor: red[900],
  },
};

export const OfferGrid:FC = () => {
  const { changesSaved, genericError } = useContext(LayoutContext);

  const [queryOptions, setQueryOptions] = useState({});
  const [sortingOptions, setSortingOptions] = useState({});

  
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PageSize[0],
  });
  
  
  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortingOptions({ sortModel: [...sortModel] });
  }, []);

  const { data, isLoading, refetch, count } = useOfferList({ page:paginationModel.page, pageSize:paginationModel.pageSize, active:true });

  const { reset, mutateAsync } = useMutation({
    mutationFn: (id:number | string) => {
      return OffersQuery.OfferUpdate(id, { active: false });
    },
  });

  const handleDelete = useCallback(async (id:number|string) => {
    reset();
    const response = await mutateAsync(id);
    if (response.status === 200) {
      await refetch();
      changesSaved();      
    } else {
      genericError();
    }
  }, []);

  
  const columns: GridColDef[] = [
    {
      field: 'name',
      width: 210,
      headerName: 'Nazwa Oferty',
      sortable:false,
      description:
            'Nazwa oferty tworzona na podstawie klientów oraz pozycji',
      filterOperators: NameOfferOnlyOperators,
    },
    {
      field: 'offer_number',
      width: 180,
      headerName: 'Numer Oferty',
      sortable:false,
      description:
              'Numer oferty tworzony na podstawie poprzednich ofert',
      filterOperators: NumberOfferOnlyOperators,
    },
    {
      field: 'user',
      width: 200,
      headerName: 'Wystawiający',
      sortable:false,
      description:
                'Handlowiec wystawiający ofertę',
      valueGetter: ({ row }) => {
        if (row.user !== null) {
          return `${row.user.first_name} ${row.user.last_name}`;
        } else {
          return 'Brak Danych';
        }},
    },
    {
      field: 'margin',
      width: 150,
      filterable: false,
      headerName: 'Marża Oferty',
      description:
                'Marża wystawiona dla danej oferty',
      cellClassName: (params) => calculateColor(params.value),
      valueGetter: ({ row }) => {
        return `${row.margin}%`;
      },
    },
    {
      field: 'date',
      width: 180,
      headerName: 'Data wystawienia',
      //filterOperators: DateOfferOnlyOperators,
      description:
                'Data wystawienia oferty',
      valueGetter: ({ row }: {row:{ date:string }}) => {
        const d = new Date(row.date);
        return `${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`;  
      },              
    },
    {
      field: 'favourited',
      width: 150,
      headerName: 'Ulubiona',
      sortable:false,
      description:
                'Ulubione Oferty przez Firmę do powielenia',
      renderCell: (params: GridRenderCellParams<Boolean>) => <FavoriteCell id={params.id} state={params.value}/>,
    },
    {
      field: 'offer_type',
      width: 150,
      headerName: 'Typ Oferty',
      sortable:false,
      valueGetter:({ row })=> {
        switch (row.offer_type) {
          case 'custom':
            return 'Inna Oferta';
          case 'catalog':
            return 'Oferta do Katalogu';
          case 'category':
            return 'Oferta do Kategorii';
          case 'default':
            return 'Oferta Domyślna';
        }
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Działania',
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem onClick={async ()=>{
          await handleDelete(params.id);
        }} icon={<Delete/>} label="Delete"/>,
      ],
    },
  ];
  
  const getDetailPanelContent = React.useCallback(({ row }:{ row:Offer }) =><div>Row ID: {row.id}</div>, []);
  const getDetailPanelHeight = React.useCallback(() => 'auto', []);

  const Grid = useMemo(()=>{
    refetch();
    return (
      <Box sx={rootStyles}>
        <DataGridPro
          loading={isLoading}
          columns={columns}
          rows={data!}
          slots={{
            detailPanelExpandIcon: ExpandMore,
            detailPanelCollapseIcon: ExpandLess,
            toolbar: GridToolbar,
          }}
          disableRowSelectionOnClick
          disableMultipleRowSelection
          disableColumnPinning
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
          rowCount={count}
          pagination
          pageSizeOptions={PageSize}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          localeText={{
            footerTotalRows:'Łączna liczba ofert',
            columnHeaderFiltersTooltipActive: (count) =>
              count !== 1 ? `${count} aktywnych filtrów` : `${count} aktywny filtr`,
            columnHeaderFiltersLabel: 'Pokaż Filtry',
            columnHeaderSortIconLabel: 'Sortuj',
            columnMenuShowColumns: 'Pokaż kolumny',
            columnMenuManageColumns: 'Zarządzaj kolumnami',
            columnMenuFilter: 'Filtruj',
            columnMenuHideColumn: 'Chowaj kolumny',
            columnMenuUnsort: 'Odsortuj',
            toolbarDensity: 'Gęstość',
            toolbarDensityLabel: 'Gęsto',
            toolbarDensityCompact: 'Kompaktowo',
            toolbarDensityStandard: 'Standardowo',
            toolbarDensityComfortable: 'Komfortowo',
            toolbarFilters: 'Filtry',
            toolbarFiltersLabel: 'Pokaż Filtry',
            toolbarFiltersTooltipHide: 'Chowaj Filtry',
            toolbarFiltersTooltipShow: 'Pokaż Filtry',
            toolbarFiltersTooltipActive: (count) =>
              count !== 1 ? `${count} aktywnych Filtrów` : `${count} aktywny filter`,
            toolbarExport: 'Exportuj',
            toolbarExportLabel: 'Exportuj',
            toolbarExportCSV: 'Pobierz jako CSV',
            toolbarExportPrint: 'Wydrukuj',
            filterPanelAddFilter: 'Dodaj filter',
            filterPanelRemoveAll: 'Usuń wszystkie',
            filterPanelDeleteIconLabel: 'Usuń',
            filterPanelLogicOperator: 'Operator Logiczny',
            filterPanelOperator: 'Operator',
            filterPanelOperatorAnd: 'I',
            filterPanelOperatorOr: 'Lub',
            filterPanelColumns: 'Kolumny',
            filterPanelInputLabel: 'Wartość',
            filterPanelInputPlaceholder: 'Wartość filtrowana',
            noRowsLabel: 'Brak wierszy',
            noResultsOverlayLabel: 'Żadnych wyników wyszukiwań',
            columnsPanelTextFieldLabel: 'Znajdź Kolumne',
            columnsPanelTextFieldPlaceholder: 'Tytuł kolumny',
            columnsPanelDragIconLabel: 'Reorder column',
            columnsPanelShowAllButton: 'Pokaż wszystkie',
            columnsPanelHideAllButton: 'Ukryj wszystkie',
          }}
        />
      </Box>
    );
  }, [data, paginationModel]);
  


  return (
    <>
      {Grid}
    </>
  );

};
