import React, { createContext, FC, ReactNode, useEffect, useContext } from 'react';

import { ContextProps } from './types';
import { useUserOrderList } from '../../hooks/orders/useUserOrder';
import { useOrderItemsList } from '../../hooks/orders/useOrderItemsList';
import { OrderQuery } from '../../clients/orders/orders';
import { UserContext, CompanyContext } from '../index';


export const defaultContext: ContextProps = {
  order: undefined,
  refetchOrder: () => {},
  orderItems: [],
  refetchOrderItems: () => {},
  count: 0,
  Loading:false,
  isLoading:false,
};


export const OrderContext = createContext(defaultContext);

export const OrderContextProvider: FC<{ children?: ReactNode }> = ({ children }) => {

  const { company } =  useContext(CompanyContext);

  const { order, isLoading, refetchOrder } = useUserOrderList();
  const { orderItems, Loading, refetchOrderItems, count } = useOrderItemsList(order?.id);


  useEffect(()=>{
    refetchOrder();
    refetchOrderItems();
  }, [company]);


  return (
    <OrderContext.Provider
      value={{
        order,
        refetchOrder,
        orderItems,
        refetchOrderItems,
        count,
        Loading,
        isLoading,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};
