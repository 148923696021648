import React, { FC, useState, useCallback, useContext, useEffect } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, 
  Button, Box, Typography, TextField, Stack, IconButton } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { Close } from '@mui/icons-material';

import { ProductCartProps } from '../../../../contexts/DialogContext/types';
import { DialogContext, LayoutContext } from '../../../../contexts';




export const OrderConfirmationDialog:FC = () => {
  const { orderDialogState, closeOrderDialog } = useContext(DialogContext);


  return (
    <Dialog open={orderDialogState} onClose={closeOrderDialog}>
      <DialogTitle>
        Złożono zamówienie
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeOrderDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close/>
      </IconButton>
      <DialogContent dividers>
        <Typography>
        Zamówienie złożone, poinformujemy Cię mailowo o przyjęciu zamówienia przez dostawcę.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};