import React, { createContext, useContext, useState, ReactNode } from 'react';


interface SwitchContextType {
  isSwitchOn: boolean;
  toggleSwitch: () => void;
}

const SwitchContext = createContext<SwitchContextType | undefined>(undefined);

interface SwitchProviderProps {
  children: ReactNode;
}

export const SwitchProvider: React.FC<SwitchProviderProps> = ({ children }) => {
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  const toggleSwitch = () => {
    setIsSwitchOn((prev) => !prev);
  };

  return (
    <SwitchContext.Provider value={{ isSwitchOn, toggleSwitch }}>
      {children}
    </SwitchContext.Provider>
  );
};

export const useSwitchContext = () => {
  const context = useContext(SwitchContext);
  if (context === undefined) {
    throw new Error('useSwitchContext must be used within a SwitchProvider');
  }
  return context;
};
