import { useQuery } from '@tanstack/react-query';

import { ItemQuery } from '../clients/catalogs/items/items';
import { cacheKeys } from '../config';


export const useItemsList = ( 
  offset: number, 
  limit: number, 
  query:string | '', 
  system_number:string | '', 
  categoryId:string | '',
  catalogId:string | '') => {
  const { data: { data }={}, isLoading, isError, refetch } = useQuery([`${cacheKeys.items.itemsList}-${offset}-${limit}-${query}-${system_number}-${categoryId}-${catalogId}`], 
    ()=>ItemQuery.ItemsList(limit, 
      offset, 
      query, 
      system_number, 
      categoryId ? Number(categoryId) : undefined, 
      catalogId ? Number(catalogId) : undefined),
    {
      enabled: true,
    },
  );

  return ({
    itemsList: data?.results ? data.results : [],
    count: data?.count || 0,
    isLoading,
    isError,
    refetch: refetch,
  });
};