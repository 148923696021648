import React, { FC, useContext, useState } from 'react';
import { Box, Drawer, List, ListItemButton, ListItem, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router';
import { ChevronRight, ExpandMore } from '@mui/icons-material';

import { LayoutContext } from '../../../contexts'; 
import { routes } from '../../../config';
import SideBar from './SideBar/SideBar';
import Skalo from '../../images/SkaloLogo.png';


export const Menu:FC = () => {
  const { navExpanded, setNavExpanded, setCatalog, setCategory } = useContext(LayoutContext);
  const naviagte = useNavigate();

  const [isCatalogExpanded, setIsCatalogExpanded] = useState(false);
  
  const handleCatalogClick = () => {
    setIsCatalogExpanded(!isCatalogExpanded);
  };

  return (
    <Drawer 
      anchor="left"
      open={navExpanded}
      onClose={()=>{
        setNavExpanded(false);
      }}
    >
      <Box sx={{ width: 360, bgcolor: 'background.paper', height:'100%', display:'flex', flexDirection:'column', paddingTop: '10px' }}>    
        <List sx={{ width:'100%' }}>
          <ListItemButton onClick={()=>{
            naviagte(routes.catalogs);
            handleCatalogClick();
          }}>
            <ListItemText primary="Katalogi"/>
            {isCatalogExpanded ? <ExpandMore/> : <ChevronRight/>}
          </ListItemButton>
          {isCatalogExpanded && <SideBar setCatalog={setCatalog} setCategory={setCategory}/>} 
          <ListItemButton onClick={()=>{
            setNavExpanded(false);
            naviagte(routes.offers);
          }}>
            <ListItemText primary="Oferty"/>
          </ListItemButton>
          <ListItemButton onClick={()=>{
            setNavExpanded(false);
            naviagte(routes.favouriteItems);
          }}>
            <ListItemText primary="Najczęściej zamawiane"/>
          </ListItemButton>
          <ListItemButton onClick={()=>{
            setNavExpanded(false);
            naviagte(routes.invoices);
          }}>
            <ListItemText primary="Faktury"/>
          </ListItemButton>
        </List>
        <img src={Skalo} alt="logo" width={200} style={{ alignSelf: 'center', paddingTop: '20px' }}/>
      </Box>
    </Drawer>
  );

};