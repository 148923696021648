import { useQuery } from '@tanstack/react-query';

import { CatalogsQuery } from '../clients/catalogs/catalogs/catalogs'; 
import { cacheKeys } from '../config';


export const useCatalogsList = () => {
  const { data: { data }={}, isLoading } = useQuery([cacheKeys.catalogs.catalogsList], 
    ()=>CatalogsQuery.CatalogsList(),
    {
      enabled: true,
    },);

  return ({
    catalogList:data?.results || [],
    isLoadingCatalog: isLoading,
  });
};