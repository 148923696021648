import React, { FC, useContext } from 'react';
import { Box, Typography, Button } from '@mui/material';

import { UserContext } from '../../../contexts';


export const AuthenticationError:FC = () => {
  const { isLoggedIn } = useContext(UserContext);
    
  if (!isLoggedIn) return (
    <Box sx={{
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center',
      height:'100vh',
    }}>
      <Typography>
        Zaloguj się by mieć dostęp do panelu dostawcy
      </Typography>
      <Button onClick={()=>{
        window.location.href = 'https://skalo.pl/logowanie';
      }}>
        Powrót do logowania
      </Button>
    </Box>        
  );
};