import { AxiosPromise, AxiosResponse } from 'axios';

import { request } from '../baserequest';
import { paginatedresult } from '../../types/pagination';
import { Invoice, InvoiceItem } from './types';


const APIURL = process.env.REACT_APP_SKALO_API;

const InvoiceList = (offset:number, limit:number, params:Partial<{company__id: number, vendor__id:number}>):AxiosPromise<paginatedresult<Invoice>> => {
  return request({
    options:{
      url: `${APIURL}invoice/`,
      method: 'GET',
      params:{
        ...params,
        offset:offset,
        limit:limit,
      },
    },
  }).then((response) => ({
    ...response,
    data: {
      ...response.data,
      results: response.data.results.map((res:any)=>{
        return { ...res,
          brutto: parseFloat(res.brutto),
          netto: parseFloat(res.netto),
          date: new Date(res.date) };
      }),
    },
  }));
};

const InvoiceItemList = (offset:number, limit:number, params:Partial<{invoice__id: number}>):AxiosPromise<paginatedresult<InvoiceItem>> => {
  return request({
    options:{
      url: `${APIURL}invoiceItem/`,
      method: 'GET',
      params:{
        ...params,
        offset: offset,
        limit: limit,
        vendor_id: 1,
      },
    },
  }).then((response) => ({
    ...response,
    data: {
      ...response.data,
      results: response.data.results,
    },
  }));
};

export const InvoiceQuery = { InvoiceList, InvoiceItemList };