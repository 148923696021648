import React, { FC, useState, useCallback, useContext, useEffect } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, 
  Button, Box, Typography, TextField, Stack, IconButton } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { NumericFormat } from 'react-number-format';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


import { DialogContext, LayoutContext, OrderContext, CompanyContext } from '../../../../contexts';
import { OrderQuery } from '../../../../clients/orders/orders';




export const DialogProduct:FC = () => {
  const { productDialogState, closeProductDialog } = useContext(DialogContext);
  const { changesSaved, setClientsNavExpanded } = useContext(LayoutContext);
  const { company } = useContext(CompanyContext);
  const { order, orderItems, refetchOrderItems, isLoading } = useContext(OrderContext);

  const [value, setValue] = useState<number>(1);

  
  const { open, image, productId, name, alt } = productDialogState;

  const addOrderItem = useMutation({
    mutationFn: (id:number) => {
      return OrderQuery.CreateOrderItem({
        order:{
          id: id },
        item:{
          id: productId! },
        quanity: value,
      });
    },
  });

  const updateOrderItem = useMutation({
    mutationFn: (id:number) => {
      return OrderQuery.UpdateOrderItem(id, { quanity: value });
    },
  });

  useEffect(()=>{
    const dat = orderItems.find((k)=> k.item.id === productId);
    if (dat) {
      setValue(parseFloat(dat.quanity));
    }
  }, [productDialogState]);

  const handleClose = useCallback(()=> {
    setValue(1);
    closeProductDialog();
  }, [productDialogState]);


  const handleAdd = useCallback(() => setValue((prev)=>prev+1), [value]);
  const handleMinus = useCallback(() => setValue((prev)=>prev === 1 ? prev : prev-1), [value]);


  const handleProductCart = useCallback(async () => {
    const dat = orderItems.find((k)=> k.item.id === productId);

    //if order item in order update, create otherwise
    if (dat === undefined) {
      const response = await addOrderItem.mutateAsync(order!?.id);
      if (response.status === 201) {
        changesSaved('Dodano Produkt do zamówienia');
        setValue(1);
      }
    } else {
      const response = await updateOrderItem.mutateAsync(dat.id);
      if (response.status === 200) {
        changesSaved('Dodano Produkt do zamówienia');
        setValue(1);
      }
    }
    closeProductDialog();
    refetchOrderItems();
  }, [value]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Dodaj Produkt
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ display:'flex', flexDirection:'row', width:300, alignItems:'center' }}>
          <img width="100px" src={image!} alt={alt}/>
          <Typography>
            {name}
          </Typography>
        </Box>
        <Box sx={{ display:'flex', flexDirection:'row', width:300, alignItems:'center', justifyContent:'center' }}>
          <NumericFormat 
            value={value} 
            allowNegative={false} 
            decimalSeparator=","
            onValueChange={(val, source)=>setValue(parseFloat(val.value))} 
            customInput={TextField}/>
          <Stack>
            <IconButton onClick={handleAdd}>
              <AddIcon/>
            </IconButton>
            <IconButton onClick={handleMinus}>
              <RemoveIcon/>
            </IconButton>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        {!company &&
        <>
          <Button onClick={()=>{
            closeProductDialog();
            setClientsNavExpanded(true);
          }}>
          Wybierz Klienta
          </Button>
        </>}
        {!isLoading && company &&
        <>
          <Button onClick={()=>{
            setValue(1);
            closeProductDialog();
          }}>
          Anuluj
          </Button>
          <Button onClick={handleProductCart}>
          Do koszyka
          </Button>
        </>}
      </DialogActions>
    </Dialog>
  );
};