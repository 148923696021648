import { dictionary } from './languages/pl';


export enum SupportedLanguage {
  Polish = 'pl',
}

export type Dictionary = typeof dictionary;

export type ContextProps = {
  language: SupportedLanguage;
  setLanguage: (lang: SupportedLanguage) => void;
  dictionary: Dictionary;
};
