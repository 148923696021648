import React from 'react';
import ReactDOM from 'react-dom/client';
import { LicenseInfo } from '@mui/x-license-pro';

import App from './App';


const APIURL = process.env.REACT_APP_SKALO_MUI_KEY;

LicenseInfo.setLicenseKey(APIURL);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);
