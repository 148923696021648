import { Box, Button, Typography, IconButton } from '@mui/material';
import { useContext, useState } from 'react';
import { FavoriteBorder, Favorite } from '@mui/icons-material';
import { useMutation } from '@tanstack/react-query';

import { useSwitchContext } from '../../../../hooks/SwitchContext';
import { DialogContext, MediaQueryContext, UserContext, LayoutContext } from '../../../../contexts';
import { FavouriteItemQuery } from '../../../../clients/catalogs/favouriteItems/favouriteItems';


interface Items {
  id: number;
  image: string;
  alt?: string;
  title: string;
  quanity?: string;
  price?: string;
  favourite?:boolean,
  refetch: ()=>any,
}

const ItemsRender: React.FC<Items> = ({
  id,
  image,
  alt,
  title,
  price,
  quanity,
  favourite,
  refetch,
}) => {
  const [isFavourite, setFavourite] = useState(favourite);
  
  const { isSwitchOn } = useSwitchContext();
  const { setproductDialogState } = useContext(DialogContext);
  const { user } = useContext(UserContext);
  const { genericError, genericInfo } = useContext(LayoutContext);
  const { isSmallerThanSm } = useContext(MediaQueryContext);

  const mutation = useMutation({
    mutationFn: () => {
      return FavouriteItemQuery.CreateFavouriteItem({ item:{
        id:id,
        name:title,
      },
      user:{ id:user?.id!,
        first_name:user?.first_name!,
        last_name:user?.last_name!,
      },
      });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: () => {
      return FavouriteItemQuery.DeleteFavouriteItem(user!?.id, id);
    },
  });

  return (
    <Box
      key={id}
      sx={{
        width: isSmallerThanSm ? '150px' : '200px',
        border: 'solid 1px #6b70755e',
        padding: isSmallerThanSm ? '4px' : '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '4px',
        transition: 'box-shadow 0.3s',
        '&:hover': {
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)', 
        
        },
      }}
      
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <IconButton  
          sx={{ display: 'flex', alignSelf: 'flex-end' }}
          onClick={async () => {
            if (!isFavourite){
              const response = await mutation.mutateAsync();
              if (response.status === 201) {
                genericInfo('Dodano do ulubionych');
                setFavourite(true);
                refetch();
              } else {
                genericError();
              }} else {
              const response = await deleteMutation.mutateAsync();
              if (response.status === 201) {
                genericInfo('Usunięto z ulubionych');
                setFavourite(false);
                refetch();
              } else {
                genericError();
              }
            }
            refetch();
          }}
        >
          {isFavourite ? 
            <Favorite color="secondary"/> : 
            <FavoriteBorder color="secondary"/>}
        </IconButton>
        <Box sx={{ height: isSmallerThanSm ? '60px' : '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img
            style={{ maxWidth: '100%', maxHeight: isSmallerThanSm ? '60px' : '100px', paddingLeft: isSmallerThanSm ? '20px' : '40px', paddingRight: isSmallerThanSm ? '20px' : '40px', marginBottom: '8px' }}
            src={image}
            alt={alt}
          />
        </Box >
      </Box>
      <Typography
        component="h2"
        sx={{
          textAlign: 'center',
          maxHeight: isSmallerThanSm ? '160px' : '200px', 
          fontSize: isSmallerThanSm ? '12px' : '16px',
          overflow:'hidden',
        }}
      >
        {title}
      </Typography>
      {price && quanity ? (
        <Box
          sx={{
            display: isSwitchOn ? 'flex' : 'none',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography
            component="p"
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            Cena netto: {parseFloat(price)} zł
          </Typography>
          <Typography
            component="p"
            sx={{
              textAlign: 'center',
            }}
          >
            Dostępne: {parseFloat(quanity)}
          </Typography>
        </Box>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          sx={{
            width: '80%',
            color: 'white',
            backgroundColor: '#c2b280',
            fontSize: isSmallerThanSm ? '10px' : '14px',
            borderRadius: '4px',
            marginTop: isSmallerThanSm ? '4px' : '8px',
            transition: 'backgroundColor 0.3s',
            '&:hover': {
              backgroundColor: '#dbc379',
            },
          }}
          onClick={()=>setproductDialogState({
            open:true,
            productId:id,
            name:title,
            image:image,
            alt:alt,
            price: parseFloat(price!),
          })}
        >
          Do koszyka
        </Button>
      </Box>
    </Box>
  );
};

export default ItemsRender;
