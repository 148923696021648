import { Box, Typography } from '@mui/material';
import React from 'react';


export const ClientOrders: React.FC = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h3" color="text.primary" sx={{ m: 2 }}>
          Moje Zamówienia
        </Typography>
      </Box>
    </Box>
  );
};
