import React, { FC } from 'react';
import { Box } from '@mui/material';


export const OfferMetaData:FC = () => {

  return (
    <Box>
         Dane oferty uwu
    </Box>
  );
};