import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Divider,
  Pagination,
  Stack,
} from '@mui/material';
import { useLocation } from 'react-router';


import { useFavoruiteUserItemsList } from '../../../hooks/useFavouriteItems';
import noImage from '../../../layouts/images/noImage.png';
import ItemsRender from '../Catalogs/components/ItemsRender';
import Loader from '../../../layouts/components/BodyLoading/Loader';
import SearchingError from '../Catalogs/catalogs/Search/SearchingError';


export const Favourite: FC = () => {
  const location = useLocation();
  const limit: number = 20;
  const [currentPage, setCurrentPage] = useState<number>(1);


  const { itemsList, count, isError, isLoading, refetch } = useFavoruiteUserItemsList();

  useEffect(()=>{
    refetch();
  }, [location]);

  
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Loader/>
      </Box>
    );
  }
  
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h3" color="text.primary" sx={{ m: 2 }}>
          Ulubione produkty
          </Typography>
        </Box>
        <Divider/>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', px: 2, pt: 2 }}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', m: 2, width: '100%',alignItems: 'center',
            marginLeft: '100px',
            marginRight: '100px' }}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            {isLoading && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '80vh',
                  width: '100%',
                }}
              >
                <Loader/>
              </Box>
            )}
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  alignItems: 'stretch',
                  gap: '20px',
                  pt: 2,
                }}
              >
                {!isLoading &&
                itemsList.map((item) => {
                  return (
                    <ItemsRender
                      key={item.id}
                      id={item.item.id!}
                      title={item.item.name}
                      image={item.item.image ? item.item.image : noImage}
                      alt={item.item.name}
                      favourite={true}
                      refetch={refetch}              
                    />
                  );
                })}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '20px 0px',
                }}
              >
                {!isLoading && count !== 0 ? (
                  <Pagination
                    count={Math.ceil(count / limit)}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                ) : (
                  !isLoading && <SearchingError/>
                )}
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </>
  );
};
