import { AxiosPromise } from 'axios';

import { request } from '../baserequest';
import { paginatedresult } from '../../types/pagination';
import { Order, OrderItem, PUTDataItem, PUTDataOrder } from './types';
import { UserNested } from '../user/types';
import { CompanyNested } from '../company/types';


const APIURL = process.env.REACT_APP_SKALO_API;


const OrderList = (
  limit: number | undefined, 
  offset: number | undefined,
  active:boolean,
  companyId?:number,
  userId?:number,
  supplierId?:number,
): AxiosPromise<paginatedresult<Order>>=> {
  return request({
    options:{
      url: `${APIURL}order/`,
      method: 'GET',
      params:{
        ...((limit !== undefined) && 
        { limit: limit }
        ),
        confirmed: active, 
        ...((offset !== undefined) && 
        { offset: offset }
        ),
        ...((companyId !== undefined) && 
        { companyId: companyId }
        ),
        ...((userId !== undefined) && 
        { userId: userId }
        ),
        ...((supplierId !== undefined) && 
        { supplierId: supplierId }
        ),
      },
    },
    authenticate:true,
  }).then((response)=>({
    ...response,
    data: {
      ...response.data,
      results: response.data.results,
    },
  }));
};


const GetLastOrder = (
  companyId?:number,
  supplierId?:number,
): AxiosPromise<{id: number}>=> {
  return request({
    options:{
      url: `${APIURL}lastOrder/`,
      method: 'GET',
      params:{
        ...((companyId !== undefined) && 
        { id_company: companyId }
        ),

        ...((supplierId !== undefined) && 
        { id_vendor: supplierId }
        ),
      },
    },
    authenticate:true,
  }).then((response)=>({
    ...response,
    data: {
      ...response.data,
      results: response.data.results,
    },
  }));
};


const OrderItemsList = ( 
  limit: number, 
  offset: number, 
  orderId?:number,
): AxiosPromise<paginatedresult<OrderItem>>=> {
  return request({
    options:{
      url: `${APIURL}orderItem/`,
      method: 'GET',
      params:{
        limit: limit,
        offset: offset,
        order__id: orderId,
      },
    },
    authenticate:true,
  }).then((response)=>({
    ...response,
    data: { 
      ...response.data,
      results: response.data.results,
    },
  }));
};

const DeleteOrderItem = ( 
  orderItemId:number | string,
): AxiosPromise=> {
  return request({
    options:{
      url: `${APIURL}orderItem/${orderItemId}/`,
      method: 'DELETE',
    },
    authenticate:true,
  }).then((res)=>{console.log(res);
    return res;
  },
  );
};

const UpdateOrder = (
  orderItemId:number | string,
  data:PUTDataOrder
): AxiosPromise=> {
  return request({
    options:{
      url: `${APIURL}order/${orderItemId}/`,
      method: 'PUT',
      data:{
        ...data,
      },
    },
    authenticate:true,
  });
};

const UpdateOrderItem = (
  orderItemId:number | string,
  data:PUTDataItem
): AxiosPromise=> {
  return request({
    options:{
      url: `${APIURL}orderItem/${orderItemId}/`,
      method: 'PUT',
      data:{
        ...data,
      },
    },
    authenticate:true,
  });
};

const CreateOrder = (data:{
  company:CompanyNested,
  supplier:CompanyNested,
  user:UserNested,
}
): AxiosPromise=> {
  
  return request({
    options:{
      url: `${APIURL}order/`,
      method: 'POST',
      data:{
        ...data,
      },
    },
    authenticate:true,
  });
};

const CreateOrderItem = (data:{
  order:{
    id: number},
  item:{id: number,
    name?: string },
  quanity: number,
}
): AxiosPromise=> {
  
  return request({
    options:{
      url: `${APIURL}orderItem/`,
      method: 'POST',
      data:{
        ...data,
      },
    },
    authenticate:true,
  });
};

export const OrderQuery = { OrderList, OrderItemsList, DeleteOrderItem, UpdateOrderItem, UpdateOrder, CreateOrder, CreateOrderItem, GetLastOrder };