import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { InvoiceQuery } from '../../clients/invoice/invoice';
import { cacheKeys } from '../../config';
import { CompanyContext } from '../../contexts';


export const useInvoiceList = (limit:number, offset:number, params:any) => {
  const { company } = useContext(CompanyContext);
  const { data: { data }={}, isLoading } = useQuery([cacheKeys.invoice.InvoiceList, company, limit, offset], 
    ()=>InvoiceQuery.InvoiceList(offset, limit, company ? { company__id:company?.id! } : {}),
    {
      enabled: !!company,
    },);

  return ({
    data:data?.results || [],
    isLoading,
    count: data?.count || 0,
  });
};