import { Box, Button, Typography } from '@mui/material';
import { useContext } from 'react';
import { FavoriteBorder } from '@mui/icons-material';

import { useSwitchContext } from '../../../../hooks/SwitchContext';
import { DialogContext, MediaQueryContext } from '../../../../contexts';


interface Items {
  id: number;
  image: string;
  alt: string;
  title: string;
  quantity: string;
  //price: string;
  count:number
}

const ItemsRender: React.FC<Items> = ({
  id,
  image,
  alt,
  title,
  //price,
  quantity,
  count,
}) => {
  const { isSwitchOn } = useSwitchContext();
  const { setproductDialogState } = useContext(DialogContext);
  const { isSmallerThanSm } = useContext(MediaQueryContext);
  
  return (
    <Box
      key={id}
      sx={{
        width: isSmallerThanSm ? '150px' : '200px',
        border: 'solid 1px #6b70755e',
        padding: isSmallerThanSm ? '4px' : '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '4px',
        transition: 'box-shadow 0.3s',
        '&:hover': {
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)', 
        
        },
      }}
      
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <FavoriteBorder color="secondary" sx={{ display: 'flex', alignSelf: 'flex-end' }}/>
        <Box sx={{ height: isSmallerThanSm ? '60px' : '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img
            style={{ maxWidth: '100%', maxHeight: isSmallerThanSm ? '60px' : '100px', paddingLeft: isSmallerThanSm ? '20px' : '40px', paddingRight: isSmallerThanSm ? '20px' : '40px', marginBottom: '8px' }}
            src={image}
            alt={alt}
          />
        </Box >
      </Box>
      <Typography
        component="h2"
        sx={{
          textAlign: 'center',
          maxHeight: isSmallerThanSm ? '160px' : '200px', 
          fontSize: isSmallerThanSm ? '12px' : '16px',
          overflow:'hidden',
        }}
      >
        {title}
      </Typography>
      <Box sx={{
        display:'flex',
        flexDirection:'column',
        flexGrow:1,
      }}/>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          component="p"
          sx={{
            textAlign: 'center',
          }}
        >
          Dostępne: <strong>{parseInt(quantity)}</strong>
        </Typography>
        <Typography
          component="p"
          sx={{
            textAlign: 'center',
          }}
        >
          Zamówiono: <strong>{count}</strong>
        </Typography>
        {/* {isSwitchOn && <Typography
          component="p"
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
            Cena netto: {parseFloat(price)} zł
        </Typography>
        } */}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          sx={{
            width: '80%',
            color: 'white',
            backgroundColor: '#c2b280',
            fontSize: isSmallerThanSm ? '10px' : '14px',
            borderRadius: '4px',
            marginTop: '8px',
            transition: 'backgroundColor 0.3s',
            '&:hover': {
              backgroundColor: '#dbc379',
            },
          }}
          onClick={()=>setproductDialogState({
            open:true,
            productId:id,
            name:title,
            image:image,
            alt:alt,
            //price: parseFloat(price),
          })}
        >
          Do koszyka
        </Button>
      </Box>
    </Box>
  );
};

export default ItemsRender;
