import React, { useContext, createContext } from 'react';
import { Box } from '@mui/material';
import { Route, Routes, Navigate } from 'react-router-dom';

import { routes } from '../config';
import { UserContext, PermissionContext } from '../contexts';
import { Permissioned } from '../layouts/Permissioned/Permissioned';
import { NotAuthenticated } from '../contexts/AuthenticationContext/Authenticated';
import { Authenticated } from '../contexts/AuthenticationContext/Authenticated';
import { ClientsMenu } from '../layouts/components/clientsMenu/ClientsMenu';
import { Menu } from '../layouts/components/Menu/Menu';
import Navbar from '../layouts/components/NavBar/Navbar';
import Loader from '../layouts/components/BodyLoading/Loader';
import Footer from '../layouts/components/Footer/Footer';
import { ClientOrders } from './Authenticated/Orders/ClientsOrders';
import Profile from './Authenticated/Profile/Profile';
import { Impersonate } from '../views/NotAuthenticated/Impersonate/Impersonate';
import { AuthenticationError } from './NotAuthenticated/NotAuthenticated/AuthenticationError';
import { Catalog } from './Authenticated/Catalogs/Catalogs';
import { Offers } from './Authenticated/Offers/Offers';
import { DeletedOffers } from './Authenticated/DeletedOffers/DeletedOffers';
import { AddOffer } from './Authenticated/AddOffer/AddOffer';
import { Basket } from './Authenticated/Basket/Basket';
import { FavouriteItems } from './Authenticated/FavouriteItems/FavouriteItems';
import { Favourite } from './Authenticated/Favourite/Favourite';
import { ManageRestaurants } from './Authenticated/ManageRestaurants/ManageResturants';
import { Invoice } from './Authenticated/Invoice/Invoice';



export const Root = () => {
  const { Loading, role } = useContext(UserContext);

  if (Loading) {
    return (
      <Box sx={{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        height:'100vh',
      }}>
        <Loader/>
      </Box>
    );
  }
  
  return (
    <Box bgcolor="background.default" 
      color="text.primary" 
      sx={{ height:'100vh' }}>
      <Box>
        <NotAuthenticated>
          <Routes>
            <Route path={routes.home} element={<AuthenticationError/>}/>
            <Route path={routes.impersonate()} element={<Impersonate/>}/>
            <Route path="*" element={<Navigate to={routes.home}/>}/>
          </Routes>
        </NotAuthenticated>
        <Authenticated>
          <Navbar/>
          <ClientsMenu/>
          <Menu/>
          <Box>
            <Routes>
              <Route path={routes.home} element={<Catalog/>}/>
              <Route path={routes.offers} element={<Offers/>}/>
              <Route path={routes.manageRestaurants} element={<ManageRestaurants/>}/>
              {/* //   <Permissioned permission={`${role || 'client'}.viewOffers`}>
              //     <Offers/>
              //   </Permissioned>
              // }/> */}
              <Route path={routes.catalogs} element={<Catalog/>}/> 
              <Route path={routes.favourite} element={<Favourite/>}/>       
              <Route path={routes.favouriteItems} element={<FavouriteItems/>}/>
              <Route path={routes.basket} element={<Basket/>}/>
              <Route path={routes.myAccount} element={<Profile/>}/>
              <Route path={routes.orders} element={<ClientOrders/>}/>
              <Route path={routes.deletedOffers} element={<DeletedOffers/>}/>
              <Route path={routes.addOffer} element={<AddOffer/>}/>
              <Route path={routes.invoices} element={<Invoice/>}/>
              <Route path="*" element={<Navigate to={routes.home}/>}/>
            </Routes>
          </Box>
          <Footer/>
        </Authenticated>
      </Box>
    </Box>
  );
};
