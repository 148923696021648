import React, { FC, useContext, useState, useCallback, useMemo } from 'react';
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridActionsCellItem,
} from '@mui/x-data-grid-pro';
import { Delete, ExpandMore, ExpandLess, Undo } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';
import { Typography, Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { UserContext } from '../../../../contexts';
import { Offer } from '../../../../clients/offers/types';
import { FavoriteCell } from './OfferCells/OfferFavouriteCell';
import { OffersQuery } from '../../../../clients/offers/offers';
import { LayoutContext } from '../../../../contexts';
import { useOfferList } from '../../../../hooks/offers/useOffersList';
import { PageSize } from '../../../../config';
import { calculateColor } from './OfferCells/OfferMarginCell';
import Loader from '../../../../layouts/components/BodyLoading/Loader';



const rootStyles = {
  width: '100%',
  '& .Green100': {
    backgroundColor: green[100],
  },
  '& .Green200': {
    backgroundColor: green[200],
  },
  '& .Green300': {
    backgroundColor: green[300],
  },
  '& .Green400': {
    backgroundColor: green[400],
  },
  '& .Green500': {
    backgroundColor: green[500],
  },
  '& .Green600': {
    backgroundColor: green[600],
  },
  '& .Green700': {
    backgroundColor: green[700],
  },
  '& .Green800': {
    backgroundColor: green[800],
  },
  '& .Green900': {
    backgroundColor: green[900],
  },
  '& .Red100': {
    backgroundColor: red[100],
  },
  '& .Red200': {
    backgroundColor: red[200],
  },
  '& .Red300': {
    backgroundColor: red[300],
  },
  '& .Red400': {
    backgroundColor: red[400],
  },
  '& .Red500': {
    backgroundColor: red[500],
  },
  '& .Red600': {
    backgroundColor: red[600],
  },
  '& .Red700': {
    backgroundColor: red[700],
  },
  '& .Red800': {
    backgroundColor: red[800],
  },
  '& .Red900': {
    backgroundColor: red[900],
  },
};

export const OfferGrid: FC = () => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PageSize[0],
  });

  const { data, isLoading, refetch, count } = useOfferList({
    page: paginationModel.page,
    pageSize: paginationModel.pageSize,
    active: false,
  });
  const { user } = useContext(UserContext);
  const { changesSaved, genericError } = useContext(LayoutContext);
  refetch();

  const mutation = useMutation({
    mutationFn: (id: number | string) => {
      return OffersQuery.OfferDelete(id);
    },
  });

  const handleDelete = async (id: number | string) => {
    const response = await mutation.mutateAsync(id);
    if (response.status === 204) {
      refetch();
      changesSaved();
    } else {
      genericError();
    }
  };

  const { reset, mutateAsync } = useMutation({
    mutationFn: (id: number | string) => {
      return OffersQuery.OfferUpdate(id, { active: true });
    },
  });

  const handleRedo = useCallback(async (id: number | string) => {
    reset();
    const response = await mutateAsync(id);
    if (response.status === 200) {
      await refetch();
      changesSaved();
    } else {
      genericError();
    }
  }, []);
  const columns: GridColDef[] = [
    {
      field: 'name',
      width: 210,
      headerName: 'Nazwa Oferty',
      description: 'Nazwa oferty tworzona na podstawie klientów oraz pozycji',
    },
    {
      field: 'offer_number',
      width: 180,
      headerName: 'Numer Oferty',
      description: 'Numer oferty tworzony na podstawie poprzednich ofert',
    },
    {
      field: 'user',
      width: 200,
      headerName: 'Wystawiający',
      description: 'Handlowiec wystawiający ofertę',
      valueGetter: ({ row }) => {
        if (row.user !== null) {
          return `${row.user.first_name} ${row.user.last_name}`;
        } else {
          return 'Brak Danych';
        }
      },
    },
    {
      field: 'margin',
      width: 150,
      headerName: 'Marża Oferty',
      description: 'Marża wystawiona dla danej oferty',
      cellClassName: (params) => calculateColor(params.value),
      valueGetter: ({ row }) => {
        return `${row.margin}%`;
      },
    },
    {
      field: 'date',
      width: 180,
      headerName: 'Data wystawienia',
      description: 'Data wystawienia oferty',
      valueGetter: ({ row }: { row: { date: string } }) => {
        const d = new Date(row.date);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
      },
    },
    {
      field: 'favourited',
      width: 150,
      headerName: 'Ulubiona',
      description: 'Ulubione Oferty przez Firmę do powielenia',
      renderCell: (params: GridRenderCellParams<Boolean>) => (
        <FavoriteCell id={params.id} state={params.value}/>
      ),
    },
    {
      field: 'offer_type',
      width: 150,
      headerName: 'Typ Oferty',
      valueGetter: ({ row }) => {
        switch (row.offer_type) {
          case 'custom':
            return 'Inna Oferta';
          case 'catalog':
            return 'Oferta do Katalogu';
          case 'category':
            return 'Oferta do Kategorii';
          case 'default':
            return 'Oferta Domyślna';
        }
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Działania',
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          onClick={() => {
            handleDelete(params.id);
            refetch();
          }}
          icon={<Delete/>}
          label="Delete"
        />,
        <GridActionsCellItem
          onClick={() => {
            handleRedo(params.id);
            refetch();
          }}
          icon={<Undo/>}
          label="Undo"
        />,
      ],
    },
  ];

  const getDetailPanelContent = React.useCallback(
    ({ row }: { row: Offer }) => <div>Row ID: {row.id}</div>,
    []
  );
  const getDetailPanelHeight = React.useCallback(() => 'auto', []);

  const Grid = useMemo(() => {
    return (
      <Box sx={rootStyles}>
        <DataGridPro
          columns={columns}
          rows={data!}
          slots={{
            detailPanelExpandIcon: ExpandMore,
            detailPanelCollapseIcon: ExpandLess,
          }}
          disableRowSelectionOnClick
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
          pagination={true}
          rowCount={count}
          pageSizeOptions={PageSize}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
        />
      </Box>
    );
  }, [data, paginationModel]);

  return (
    <>
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Loader/>
        </Box>
      )}
      {!isLoading && count === 0 && (
        <Typography>Brak Aktywnych Ofert</Typography>
      )}
      {!isLoading && count !== 0 && Grid}
    </>
  );
};
