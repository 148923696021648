import { AxiosResponse } from 'axios';

import { userrequest } from '../baseuserrequest';
import { request } from '../baserequest'; 
import { WrongData, UserResponse, User } from './types';


const baseurl = process.env.REACT_APP_SKALO_API;


const loginUser = (data:{ password:string, email:string}): Promise<AxiosResponse<UserResponse>> | Promise<WrongData> => {
  return userrequest({
    options:{
      method: 'POST',
      url: `${baseurl}login/`,
      data: data,
    },
    authenticate: false,
  }).catch((e)=>({ data:{ data:'wrong data', status:403 } }));
};

const registerUser = (username:string, 
  email:string, 
  password:string, 
  first_name:string, 
  last_name:string, 
  phone:string): Promise<AxiosResponse<UserResponse>> => {

  return request({
    options: {
      url:`${baseurl}signup/`,
      method:'POST',
      data: {
        username:username,
        email:email,
        password:password,
        first_name:first_name,
        last_name:last_name,
        phone:phone,
      },
    },
    authenticate:false,
  });
};

const impersonateUser = (token:string): Promise<AxiosResponse<User>> => {
  return request({
    options:{
      method: 'GET',
      url: `${baseurl}users/me/`,
    },
  }).then((response)=>({
    ...response,
    data: {
      ...response.data,
    },
  }));
};


export const UserClient = { impersonateUser, registerUser, loginUser };