import React, { FC, useContext } from 'react';
import { Box, Typography, Divider,
  Button, StepButton, Step, Stepper  } from '@mui/material';
  
import { OfferMetaData } from './OfferStepper/MetaDataOffer';
import { OfferClients } from './OfferStepper/OfferClients';
import { AddProduct } from './OfferStepper/AddProduct';
import { Summary } from './OfferStepper/Summary';
import { OffersButtons } from '../../../layouts/components/OffersButtons/OffersButtons';
import { MediaQueryContext } from '../../../contexts';


const steps = ['Ustaw Dane Oferty', 'Dodaj Klientów', 'Dodaj Produkty', 'Podsumowanie'];

export const AddOffer: FC = () => {

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
      // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const { isSmallerThanLg } = useContext(MediaQueryContext);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isSmallerThanLg ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: isSmallerThanLg ? 'center' : '',
        }}
      >
        <Typography variant="h3" color="text.primary" sx={{ m: 2 }}>
          Dodawanie oferty
        </Typography>
        <OffersButtons/>
      </Box>
      <Divider/>
      <Box sx={{ width: '100%', p:3 }}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
              <Button>
              </Button>
            </Step>
          ))}
        </Stepper>
        <div>
          {allStepsCompleted() ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>Dodawanie Oferty Zakończone
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }}/>
                <Button onClick={handleReset}>Zaakceptuj i wróć do Ofert</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {activeStep === 0 && 
              <OfferMetaData/>}
              {activeStep === 1 && 
              <OfferClients/>}
              {activeStep === 2 && 
              <AddProduct/>}
              {activeStep === 3 && 
              <Summary/>}

              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }}/>
                <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
                </Button>
                {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography variant="caption" sx={{ display: 'inline-block' }}>
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1
                      ? 'Finish'
                      : 'Complete Step'}
                  </Button>
                ))}
              </Box>
            </React.Fragment>
          )}
        </div>
      </Box>
      
    </Box>
  );
};
