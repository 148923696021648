import React, { FC, createContext, useState, useCallback, useEffect, useContext, useMemo } from 'react';
import { useTheme, useMediaQuery, Snackbar as MuiSnackbar, Alert } from '@mui/material';
import { Brightness4, Brightness7 } from '@mui/icons-material';

import { LocalizationContext } from '../LocalizationContext/LocalizationContext';
import { UserContext } from '../UserContext/UserContext';
import { ContextProps, Mode } from './types';


const defaultContext: ContextProps = {
  mode: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
  setMode: () => null,
  toggleMode: () => null,
  adminView: false,
  isMobile: false,
  mobileMenuOpen: false,
  setMobileMenuOpen: () => null,
  navExpanded: false,
  setNavExpanded: () => null,
  clientsNavExpanded: false,
  setClientsNavExpanded: () => null,

  snackbarState: {
    open: false,
    message: '',
    severity: 'info',
    autoHideDuration: 5000,
  },
  setSnackbarState: () => null,
  closeSnackbar: () => null,
  changesSaved: () => null,
  csvChangesSaved: () => null,
  genericError: () => null,
  genericInfo: () => null,
  validationError: () => null,
  ToggleModeIcon: Brightness4,
  navWidth: 240,
  mesWidth: 90,
  isLightMode: true,
  Snackbar: null,
  query: '',
  setQuery: () => null,
  catalog: undefined,
  setCatalog: () => null,
  category: undefined,
  setCategory: () => null,
  currentPage: undefined,
  setCurrentPage: () => null,
};

export const LayoutContext = createContext(defaultContext);

interface Props {
  children: React.ReactNode;
}

export const LayoutContextProvider: FC<Props> = ({ children }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { isAdmin } = useContext(UserContext);
  const { dictionary } = useContext(LocalizationContext);
  const [mode, setMode] = useState<Mode>('light');
  const [snackbarState, setSnackbarState] = useState(defaultContext.snackbarState);
  const [navExpanded, setNavExpanded] = useState(defaultContext.navExpanded);
  const [clientsNavExpanded, setClientsNavExpanded] = useState(defaultContext.clientsNavExpanded);
  const navWidth = useMemo(() => navExpanded ? defaultContext.navWidth : 90, [navExpanded]);
  const mesWidth = defaultContext.mesWidth;

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const [catalog, setCatalog] = useState<
  { name: string; id: string } | undefined
>(undefined);
  const [category, setCategory] = useState<
  { name: string; id: string } | undefined
>(undefined);

  const closeSnackbar = useCallback(() => {
    setSnackbarState({
      ...snackbarState,
      open: false,
    });
  }, [snackbarState]);

  const changesSaved = useCallback((message: string = dictionary.dialogs.changesSaved) => {
    setSnackbarState({
      open: true,
      message: message,
      severity: 'success',
      autoHideDuration: 2000,
    });
  }, [dictionary.dialogs.changesSaved]);

  const csvChangesSaved = useCallback(() => {
    setSnackbarState({
      open: true,
      message: dictionary.dialogs.csvChangesSaved,
      severity: 'success',
      autoHideDuration: 5000,
    });
  }, [dictionary.dialogs.csvChangesSaved]);

  const validationError = useCallback((message: string = dictionary.forms.validations.oneOrMoreFieldsAreIncorrect) => {
    setSnackbarState({
      open: true,
      message: message,
      severity: 'error',
      autoHideDuration: 3000,
    });
  }, [dictionary]);

  const genericError = useCallback(() => {
    setSnackbarState({
      open: true,
      message: dictionary.somethingWentWrong,
      severity: 'error',
      autoHideDuration: 2000,
    });
  }, [dictionary]);

  const genericInfo = useCallback((message: string = '') => {
    setSnackbarState({
      open: true,
      message: message,
      severity: 'success',
      autoHideDuration: 6000,
    });
  }, []);

  const toggleMode = useCallback(() => {
    setMode(mode === 'dark' ? 'light' : 'dark');
  }, [mode]);

  const ToggleModeIcon = mode === 'dark' ? Brightness7 : Brightness4;
  const isLightMode = mode === 'light';

  useEffect(() => {
    localStorage.setItem('mode', mode);
  }, [mode]);

  const Snackbar = useMemo(() => (
    <MuiSnackbar
      open={snackbarState.open}
      autoHideDuration={snackbarState.autoHideDuration}
      onClose={closeSnackbar}
    >
      <Alert onClose={closeSnackbar} severity={snackbarState.severity}>
        {snackbarState.message}
      </Alert>
    </MuiSnackbar>
  ), [snackbarState, closeSnackbar]);

  return (
    <LayoutContext.Provider
      value={{
        mode,
        setMode,
        toggleMode,
        mobileMenuOpen,
        setMobileMenuOpen,
        navExpanded,
        setNavExpanded,
        navWidth,
        mesWidth,
        adminView: isAdmin,
        isMobile,
        Snackbar,
        snackbarState,
        setSnackbarState,
        closeSnackbar,
        changesSaved,
        csvChangesSaved,
        genericError,
        genericInfo,
        validationError,
        ToggleModeIcon,
        isLightMode,
        clientsNavExpanded, 
        setClientsNavExpanded,
        query,
        setQuery,
        catalog,
        setCatalog,
        category,
        setCategory,
        currentPage,
        setCurrentPage,
      }}
    >
      <>
        {Snackbar}
        {children}
      </>
    </LayoutContext.Provider>
  );
};
