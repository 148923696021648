import React, { FC, useContext, useState } from 'react';
import { Box, Typography } from '@mui/material';

import { CompanyContext } from '../../../contexts';
import { useInvoiceList } from '../../../hooks/Invoice/useInvoiceList';
import { InvoiceGrid } from './InvoiceGrid/InvoiceGrid';
import { PageSize } from '../../../config';


export const Invoice = () => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PageSize[0],
  });
  const { company } = useContext(CompanyContext);
  const { isLoading, data, count } = useInvoiceList(paginationModel.pageSize, paginationModel.page * paginationModel.pageSize, {});

  if (!company) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h3" color="text.primary" sx={{ m: 2 }}>
              Faktury
          </Typography>
        </Box>
        Wybierz restauracje
      </Box>
    );
  }


  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h3" color="text.primary" sx={{ m: 2 }}>
              Faktury
          </Typography>
        </Box>
        Ładowanie
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h3" color="text.primary" sx={{ m: 2 }}>
            Faktury
        </Typography>
      </Box>
      <InvoiceGrid 
        isLoading={isLoading} 
        data={data}
        count={count}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}/>
    </Box>
  );
};