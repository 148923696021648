import { createTheme } from '@mui/material/styles';


export const theme = createTheme({
  palette: {
    secondary: {
      main: '#c2b280',
    },
  },
});

export default theme;
