import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { OrderQuery } from '../../clients/orders/orders';
import { UserContext, CompanyContext } from '../../contexts';
import { cacheKeys } from '../../config';


export const useUserOrderList = () => {

  const { user } = useContext(UserContext);
  const { company } = useContext(CompanyContext);

  const { data: { data }={}, isLoading, refetch } = useQuery({ queryKey:[cacheKeys.order.orderList, user?.first_name, user?.last_name, company?.name ], 
    queryFn:()=>OrderQuery.GetLastOrder(company?.id, 1),
    enabled: !!user && !!company,
  });

  return ({
    order: data, isLoading, refetchOrder:refetch,
  });
};