import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { InvoiceQuery } from '../../clients/invoice/invoice';
import { cacheKeys } from '../../config';
import { CompanyContext } from '../../contexts';


export const useInvoiceItemList = (params:any) => {
  const { company } = useContext(CompanyContext);
  const { data: { data }={}, isLoading } = useQuery([cacheKeys.invoiceItem.InvoiceItemList, params?.invoice__id], 
    ()=>InvoiceQuery.InvoiceItemList(0, 110, { invoice__id: params?.invoice__id }),
    {
      enabled: !!company && !!params?.invoice__id,
    },);
  return ({
    data:data?.results || [],
    isLoading,
    count: data?.count || 0,
  });
};