import { Box, Button, Switch, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import { LayoutContext, MediaQueryContext } from '../../../../contexts';
import { CompanyQuery } from '../../../../clients/company/company';


interface Restaurant {
  id: number;
  name: string;
  active: boolean;
} 

  

const RestaurantRender: React.FC<Restaurant> = ({
  id,
  name,
  active,
}) => {
  const { isSmallerThanSm } = useContext(MediaQueryContext); 
  const [isActive, setIsActive] = useState(active);
  const { changesSaved, genericError } = useContext(LayoutContext);

  const { mutateAsync, reset, isSuccess, isError } = useMutation({
    mutationFn: () => {
      return CompanyQuery.ToggleCompanyActive(id, { active:!isActive });
    },
  });

  const handleChange = async () => {
    reset();
    const data = await mutateAsync();
    if (isSuccess) {
      setIsActive(data.data.active);
      changesSaved();      
    } else if (isError) {
      genericError();
    }
  };

  return (
    <>
      <Box
        key={id}
        sx={{
          width: '60%',
          border: 'solid 1px #6b70755e',
          padding: isSmallerThanSm ? '4px' : '8px',
          margin: isSmallerThanSm ? '4px' : '8px',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          alignSelf: 'center',
          borderRadius: '4px',
        }}
      
      >
    
        <Typography
          component="h2"
          sx={{
            textAlign: 'center',
            maxHeight: isSmallerThanSm ? '160px' : '200px', 
            fontSize: isSmallerThanSm ? '12px' : '16px',
            overflow:'hidden',
          }}
        >
          {name}
        </Typography>
    
        <Typography>
          {isActive ? 'Restauracja aktywna' : 'Restauracja nieaktywna'}
        </Typography>
        <Switch
          color="secondary"
          checked={isActive}
          onChange={() => handleChange()}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        
      </Box>
    </>
  );
};

export default RestaurantRender;
