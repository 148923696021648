import { AxiosPromise } from 'axios';

import { request } from '../../baserequest';
import { Catalogs } from './types';
import { paginatedresult } from '../../../types/pagination';


const APIURL = process.env.REACT_APP_SKALO_API;

const CatalogsList = ():AxiosPromise<paginatedresult<Catalogs>> => {
  return request({
    options:{
      url: `${APIURL}cataloglist/`,
      method: 'GET',
    },
  }).then((response) => ({
    ...response,
    data: {
      ...response.data,
      results: response.data.results,
    },
  }));
};

export const CatalogsQuery = { CatalogsList };
