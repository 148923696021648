import React, { FC } from 'react';
import {
  Box,
  List,
  Switch,
} from '@mui/material';

import { CatalogList } from '../catalogs/CatalogList';
import { useSwitchContext } from '../../../../../hooks/SwitchContext';


type Props = {
  setCategory: any,
  setCatalog: any
}

const SideBar: FC<Props> = ({ setCategory, setCatalog }) => {
  const { toggleSwitch } = useSwitchContext();

  const handleSwitchChange = () => {
    toggleSwitch();
  };


  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <List sx={{ width:280 }}>
        <CatalogList setCatalog={setCatalog} setCategory={setCategory}/>
      </List>
      <Switch color="secondary" onChange={handleSwitchChange}/>
    </Box>
  );
};

export default SideBar;
