import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import {
  AppBar,
  Avatar,
  Box,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  Badge,
  Button,
} from '@mui/material';
import { Favorite, ShoppingBasket, TrendingUp } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';

import AddAll from '../../images/addall.png';
import Skalo from '../../images/SkaloLogo.png';
import {
  StyledLink,
  StyledToolbar,
  UserBox,
} from '../StyledComponents/StyledComponents';
import { UserContext, LayoutContext, PermissionContext, OrderContext, CompanyContext } from '../../../contexts';
import { routes } from '../../../config';
import { MediaQueryContext } from '../../../contexts/MediaQueryContext/MediaQueryContext';


const Navbar: React.FC = () => {
  const { role, user, logout } = useContext(UserContext);
  const { count } = useContext(OrderContext);
  const { company } = useContext(CompanyContext);

  const { getPermission } = useContext(PermissionContext);
  const { setNavExpanded, setClientsNavExpanded, setQuery, setCatalog, setCategory, setCurrentPage } = useContext(LayoutContext);

  const navigate = useNavigate();
  const { isSmallerThanMd } = useContext(MediaQueryContext);

  const [open, setOpen] = useState(false);

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: '#c2b280',
        height: 'auto',
      }}
    >
      <StyledToolbar>
        <IconButton onClick={()=>{setNavExpanded(true);}}>
          <MenuIcon/>
        </IconButton>    
        <StyledLink to={routes.home}>
          <Box sx={{ display: 'grid',
            gridTemplateColumns: 'auto auto auto',
            alignItems: 'center',
            justifyContent:'center',
            gap: '5px' }}
          onClick={() => {
            setCatalog(undefined);
            setCategory(undefined);
            setQuery('');
            setCurrentPage(1);}}>
            <img
              src={AddAll}
              alt="logo"
              style={{ width: isSmallerThanMd ? '50px' : '70px' }}
            />
            <Typography
              sx={{
                fontSize: '20px',
              }}
            >
                by
            </Typography>
            <img src={Skalo} alt="logo" style={{ height: isSmallerThanMd ? '36px' : '50px' }}/>
          </Box>
        </StyledLink>
        <Box sx={{ flexGrow:1, display: { xs: 'none', sm: 'block' } }}/>
        <Box sx={{ display: 'flex' }}>
          <Button sx={{ alignSelf: 'center', fontWeight: 'bold', color: 'white', '&:hover': { backgroundColor: 'transparent' } }} onClick={()=>{setClientsNavExpanded(true);}}>
            {company ? company.name : ''}
          </Button>
          <Badge 
            badgeContent={count} 
            color="success"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}>
            <IconButton 
              onClick={()=> navigate(routes.basket)}>
              <ShoppingBasket
                sx={{
                  color: 'white',
                  width: isSmallerThanMd ? '30px' : '40px',
                  height: isSmallerThanMd ? '30px' : '40px',
                }}
              />
            </IconButton>
          </Badge>
          <IconButton 
            onClick={()=> navigate(routes.favouriteItems)}>
            <TrendingUp sx={{ color: 'white',
              width: isSmallerThanMd ? '30px' : '40px',
              height: isSmallerThanMd ? '30px' : '40px' }}/>
          </IconButton>
          <IconButton 
            onClick={()=> navigate(routes.favourite)}>
            <Favorite sx={{ color: 'red', 
              width: isSmallerThanMd ? '30px' : '40px',
              height: isSmallerThanMd ? '30px' : '40px' }}/>
          </IconButton>          
          <Button
            onClick={() => setOpen(true)}
            sx={{ color: 'white' }}
          >
            <Avatar src={user?.profile_picture} 
              sx={{
                width: isSmallerThanMd ? '30px' : '40px',
                height: isSmallerThanMd ? '30px' : '40px',
                marginRight: '8px',
              }}/>
            <Typography
              variant="body1"
              sx={{ display: { xs: 'none', sm: 'block' }, fontSize: '20px' }}
            >
              {`${user?.first_name?.charAt(0)}${user?.last_name?.charAt(0)}`}
            </Typography>
          </Button>
          {getPermission(`${role || 'client'}.viewClientsList`) &&
            <IconButton onClick={()=>{setClientsNavExpanded(true);}}>
              <MenuIcon/>
            </IconButton>
          }
        </Box>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          open={open}
          onClose={(e) => setOpen(false)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={()=> {navigate(routes.home); setOpen(false);}}>
              Profil
          </MenuItem>
          <MenuItem onClick={()=> {navigate(routes.basket); setOpen(false);}}>
              Moje zamówienia
          </MenuItem>
          <MenuItem onClick={()=> {navigate(routes.manageRestaurants); setOpen(false);}}>
              Zarządzaj Restauracjami
          </MenuItem>
          <MenuItem onClick={()=>{
            logout();
          }}>Wyloguj się</MenuItem>
        </Menu>
      </StyledToolbar>
    </AppBar>
  );
};

export default Navbar;
