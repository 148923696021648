import { AxiosPromise } from 'axios';

import { request } from '../../baserequest';
import { paginatedresult } from '../../../types/pagination';
import { FavouriteItem } from './types';
import { item } from '../items/types';



const APIURL = process.env.REACT_APP_SKALO_API;

const CreateFavouriteItem = (data:FavouriteItem) => {
  
  return request({
    options:{
      url:`${APIURL}favouriteItem/`,
      method:'POST',
      data:{
        ...data,
      },
    },
  });
};

const DeleteFavouriteItem = (userId:number, itemId:number) => {
  return request({
    options:{
      url:`${APIURL}deleteFavouriteItems/`,
      method:'POST',
      data:{
        userId: userId,
        itemId: itemId,
      },
    },
  });
};

const FavouriteItemList = (offset:number, limit:number): AxiosPromise<paginatedresult<FavouriteItem>> => {
  return request({
    options:{
      url:`${APIURL}favouriteItemsList/`,
      method:'GET',
    },
  }).then((response)=>({
    ...response,
    data:{
      ...response.data,
    },
  }));
};


export const FavouriteItemQuery = { CreateFavouriteItem, DeleteFavouriteItem, FavouriteItemList };