import queryString from 'query-string';
import axiosRequest, { AxiosRequestConfig, AxiosPromise } from 'axios';


export type AbstractRequest = <T = any, D = any>(props: { options: AxiosRequestConfig<D>; authenticate?: boolean; maxRetries?: number; }) => AxiosPromise<T>;

const serializeParams = (params: any) => queryString.stringify(params);

const attachOptions = (options: AxiosRequestConfig ) => {

  const selectedLanguage = typeof localStorage !== 'undefined' ? localStorage.getItem('ar-language') || 'pl' : 'pl';
  
  return {
    ...options,
    paramsSerializer: (params: any) => serializeParams(params),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': selectedLanguage,
      'Cache-Control': 'max-age=0',
      ...options.headers,
    },
  };
};

export const userrequest: AbstractRequest = async ({ options, maxRetries = 3 }) => {
  const optionsWithHeader = attachOptions(options);
  return axiosRequest(optionsWithHeader);
};
