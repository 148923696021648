import React, { createContext, FC, ReactNode, useState } from 'react';

import { ContextProps } from './types';


export const defaultContext: ContextProps = {
  company: undefined,
  selectCompany: () => {},
};


export const CompanyContext = createContext(defaultContext);

export const CompanyContextProvider: FC<{ children?: ReactNode }> = ({ children }) => {

  const [company, setCompany] = useState(undefined);

  
  return (
    <CompanyContext.Provider
      value={{
        company: company || undefined,
        selectCompany: setCompany,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};
