import React, { FC, ReactNode, createContext, useState, useCallback } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';

import { ContextProps, PermissionKey, Permissions } from './types';


const defaultPermissions: Permissions = {
  user: {
    crud: { list: false, view: false, create: false, edit: false, delete: false },
  },
  company: {
    crud: { list: false, view: false,  create: false, edit: false, delete: false },
  },
  client: { 
    viewOrders: true, viewOffers:false, viewClientsList:false,
  },
  trader: { 
    viewOrders: true, viewOffers:true, viewClientsList:true,
  },
  company_admin: { 
    viewOrders: true, viewOffers:true, viewClientsList:true,
  },
  supplier: { 
    viewOrders: true, viewOffers:false, viewClientsList:true,
  },

};

Object.freeze(defaultPermissions);

export const defaultContext: ContextProps = {
  permissions: cloneDeep(defaultPermissions),
  getPermission: () => false,
  setPermissions: () => {},
  resetPermissions: () => {},
};

export const PermissionContext = createContext(defaultContext);

export const PermissionContextProvider: FC<{ children?: ReactNode }> = ({ children }) => {

  const [permissions, setStatePermissions] = useState<Permissions>(cloneDeep(defaultPermissions));

  const getPermission = useCallback((permissionKey: PermissionKey) => {
    return get(permissions, permissionKey);
  }, [permissions]);

  const setPermissions = useCallback((permissionKeys: PermissionKey[], value: boolean) => {
    const updatedPermissions = {};
    permissionKeys.forEach(key => set(updatedPermissions, key, value));
    setStatePermissions(permissions => cloneDeep(merge(permissions, updatedPermissions)));
  }, []);

  const resetPermissions = useCallback(() => {
    setStatePermissions(cloneDeep(defaultPermissions));
  }, []);

  return (
    <PermissionContext.Provider
      value={{
        permissions,
        getPermission,
        setPermissions,
        resetPermissions,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};
