import React, { FC, useContext } from 'react';
import {
  Box,
  Typography,
  Divider,
  Pagination,
} from '@mui/material';

import { LayoutContext, PermissionContext, UserContext } from '../../../contexts';
import { useCompanyList } from '../../../hooks/useCompanyList';
import SearchingError from '../Catalogs/catalogs/Search/SearchingError';
import SearchInput from '../Catalogs/catalogs/Search/Search';
import RestaurantRender from './restaurantsReder/RestaurantsRender';


export const ManageRestaurants: FC = () => {
  const { getPermission } = useContext(PermissionContext);
  const { role } = useContext(UserContext);
  
  const limit: number = 20;
  

  const { query, setQuery, currentPage, setCurrentPage } =
    useContext(LayoutContext);

  const { data, count, isLoading } = useCompanyList(
    limit,
    (currentPage - 1) * limit,
    { name:query }
  );

  const handleChange = (value: string) => {
    setQuery(value);
    setCurrentPage(1);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h3" color="text.primary" sx={{ m: 2 }}>
          Zarządzaj restauracjami
          </Typography>
        </Box>
        <Divider/>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '40%' }}>
        <SearchInput query={query} handleChang={handleChange}/>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', px: 2, pt: 2 }}>
        {getPermission(`${role || 'client'}.viewClientsList`)}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {!isLoading &&
                data.map((restaurant) => {
                  return (
                    <RestaurantRender
                      id={restaurant.id}
                      name={restaurant.name}
                      key={restaurant.id}
                      active={restaurant.active}
                    />
                  );
                })}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '20px 0px',
          }}
        >
          {!isLoading && count !== 0 ? (
            <Pagination
              count={Math.ceil(count / limit)}
              page={currentPage}
              onChange={handlePageChange}
            />
          ) : (
            !isLoading && <SearchingError/>
          )}
        </Box>
      </Box>
    </>
  );
};
