import React, { createContext, ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { MediaQueryContextType } from './types';


export const defaultContext: MediaQueryContextType = {
  isSmallerThanSm: false,
  isSmallerThanMd: false,
  isSmallerThanLg: false,
  isSmallerThanXl: false,
  isLargerThanSm: false,
  isLargerThanMd: false,
  isLargerThanLg: false,
  isLargerThanXl: false,
};

export const MediaQueryContext = createContext(defaultContext);

interface MediaQueryProviderProps {
  children: ReactNode;
}

export const MediaQueryContextProvider = ({ children }: MediaQueryProviderProps) => {
  const theme = useTheme();
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallerThanLg = useMediaQuery(theme.breakpoints.down('lg'));
  const isSmallerThanXl = useMediaQuery(theme.breakpoints.down('xl'));

  const isLargerThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLargerThanLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isLargerThanXl = useMediaQuery(theme.breakpoints.up('xl'));

  const contextValue: MediaQueryContextType = {
    isSmallerThanSm,
    isSmallerThanMd,
    isSmallerThanLg,
    isSmallerThanXl,
    isLargerThanSm,
    isLargerThanMd,
    isLargerThanLg,
    isLargerThanXl,
  };

  return (
    <MediaQueryContext.Provider value={contextValue}>
      {children}
    </MediaQueryContext.Provider>
  );
};

