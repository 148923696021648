import React, { useRef } from 'react';
import { Box, TextField } from '@mui/material';
import {
  GridFilterInputValueProps,
  DataGrid,
  GridFilterItem,
  GridFilterOperator,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';


function NumberInputValue(props: GridFilterInputValueProps) {
  const { item, applyValue, focusElementRef } = props;
  
  const inputRef: React.Ref<any> =useRef(null);
  React.useImperativeHandle(focusElementRef, () => ({
    focus: () => {
      inputRef.current
        .querySelector(`input[value="${item.value || ''}"]`)
        .focus();
    },
  }));
  
  const handleFilterChange = (event:any) => {
    applyValue({ ...item, value: event.target.value });
  };
  
  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        pl: '20px',
      }}
    >
      <TextField 
        id="input field for offerts filtering" 
        label="Numer Oferty" 
        variant="outlined"
        ref={inputRef}
        value={item.value || ''}
        onChange={handleFilterChange}/>
    </Box>
  );
}

export const NumberOfferOnlyOperators: GridFilterOperator[] = [
  {
    label: 'zaczyna się od',
    value: 'starts_whith',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }
  
      return (params): boolean => {
        return true;
      };
    },
    InputComponent: NumberInputValue,
    InputComponentProps: { type: 'string' },
    getValueAsString: (value: string) => `${value}`,
  },
  {
    label: 'dokładna wartość',
    value: 'iexact',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }
  
      return (params): boolean => {
        return true;
      };
    },
    InputComponent: NumberInputValue,
    InputComponentProps: { type: 'string' },
    getValueAsString: (value: string) => `${value}`,
  },
];