import React, { FC, useContext, useState, useEffect } from 'react';
import { Box, Drawer, List, ListItemButton, ListItem, ListItemText, Pagination, TextField } from '@mui/material';

import { LayoutContext, CompanyContext } from '../../../contexts';
import { useCompanyList } from '../../../hooks/useCompanyList';
import Loader from '../BodyLoading/Loader';


export const ClientsMenu:FC = () => {
  const { clientsNavExpanded, setClientsNavExpanded } = useContext(LayoutContext);
  const { selectCompany, company } = useContext(CompanyContext);
  
  const [page, setPage] = useState(1);
  const [name, setName] = useState('');

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  
  const { data, count, isLoading } = useCompanyList(10, page*10 - 10, { name: name,
    active:true });

  useEffect(()=>{
    setPage(1);
  }, [name]);

  return (
    <Drawer 
      anchor="right"
      open={clientsNavExpanded}
      onClose={()=>{
        setName('');
        setClientsNavExpanded(false);
      }}
    >
      <Box sx={{ width: 360, bgcolor: 'background.paper', height:'100%', display:'flex', flexDirection:'column' }}>
        <Box sx={{
          display:'flex',
          justifyContent:'center',
          pt:2,
          px:2,
        }}>
          <TextField 
            fullWidth 
            id="supplier-search" 
            label="Wyszukaj klienta"
            value={name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
            }}/>
        </Box>
        {isLoading &&
        <Box sx={{
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          height:'100%',
        }}>
          <Loader/>
        </Box>}

        {!isLoading && <>
          <List>
            {data
              .map((client) => (
                <ListItem key={client.id}>
                  <ListItemButton
                    onClick={() => {
                      selectCompany({
                        name: client.name,
                        id: client.id,
                      });
                      setClientsNavExpanded(false);
                    }}
                    selected={company?.id === client.id}
                  >
                    <ListItemText primary={client.name} secondary={client.sname}/>
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
          <Box sx={{
            flexGrow:1,
          }}/>
          <Box sx={{
            display:'flex',
            justifyContent:'center',
          }}>
            <Pagination count={Math.ceil(count/10)} page={page} onChange={handleChange}/>
          </Box>
        </>}
      </Box>
    </Drawer>
  );

};