import { useState, FC, useContext } from 'react';
import {
  Icon,
  List,
  ListItemText,
  ListItemButton,
} from '@mui/material';
import { ChevronRight, ExpandMore } from '@mui/icons-material';

import { useCategoryList } from '../../../../hooks/useCategoryList'; 
import { useCatalogsList } from '../../../../hooks/useCatalogsList';
import { LayoutContext } from '../../../../contexts';


const ListItemNavigation:FC<any> = ({ categoryList, catalog, setCategory, setCatalog, selected, setSelected }) => {

  const { setQuery } = useContext(LayoutContext);

  return (
    <>
      <ListItemButton key={catalog.id} onClick={() =>{
        setCatalog({ name:catalog.name,
          id:catalog.id });
        setSelected((prev: undefined | number)=>{
          if (prev === catalog.id) {
            return undefined;
          } else {
            return catalog.id;
          }
        });
        setCategory(undefined);
        setQuery('');
      } }>
        <ListItemText primary={catalog.name}/>
        <Icon>
          {selected === catalog.id ? <ExpandMore/> : <ChevronRight/>}
        </Icon>
      </ListItemButton>
      {(selected === catalog.id) && (
        <List>
          {categoryList
            .filter((category:any) => category.catalog.id === catalog.id)
            .map((category:any) => (
              <ListItemButton key={category.id} onClick={() => {
                setCategory({ name:category.name,
                  id:category.id });
                setCatalog({ name:catalog.name,
                  id:catalog.id });
                setQuery('');
              }}>
                <ListItemText sx={{ color: '#595959' }}>- {category.name}</ListItemText>
              </ListItemButton>
            ))}
        </List>
      )}
    </>
  );
};


type Props = {
  setCategory: any,
  setCatalog: any
}

export const CatalogList: FC<Props> = ({ setCategory, setCatalog }) => {
  const { setQuery } = useContext(LayoutContext);
  
  const [selected, setSelected] = useState(undefined);
  const { catalogList } = useCatalogsList();
  const { categoryList } = useCategoryList();

  return (
    <List>

      {catalogList.map((catalog) => (
        <ListItemNavigation 
          key={catalog.id} 
          catalog={catalog} 
          categoryList={categoryList}
          setCatalog={setCatalog} 
          setCategory={setCategory}
          selected={selected}
          setSelected={setSelected}/>
      ))}
    </List>
  );
};

