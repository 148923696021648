import React, { FC, useContext } from 'react';
import { Box, Typography, Divider } from '@mui/material';

import { OfferGrid } from './OffersGrid/OfferTable';
import { OffersButtons } from '../../../layouts/components/OffersButtons/OffersButtons';
import { MediaQueryContext } from '../../../contexts';


export const Offers: FC = () => {
  const { isSmallerThanLg } = useContext(MediaQueryContext);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isSmallerThanLg ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: isSmallerThanLg ? 'center' : '',
        }}
      >
        <Typography variant="h3" color="text.primary" sx={{ m: 2 }}>
          Oferty
        </Typography>
        <OffersButtons/>
      </Box>
      <Divider>
        <Typography variant="h4" color="text.primary" sx={{ m: 2 }}>
          Aktualne Oferty
        </Typography>
      </Divider>
      <OfferGrid/>
    </Box>
  );
};
