import React, { useState, useContext } from 'react';
import { FavoriteBorder, Favorite } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { OffersQuery } from '../../../../../clients/offers/offers';
import { LayoutContext } from '../../../../../contexts';


export const FavoriteCell = ({ state, id }: {state:boolean, id:number | string}) => {
  const [favourite, setFavourite] = useState(state);
  const { changesSaved, genericError } = useContext(LayoutContext);

  const { mutateAsync, reset, isSuccess, isError } = useMutation({
    mutationFn: () => {
      return OffersQuery.OfferUpdate(id, { favourited:!favourite });
    },
  });

  const handleChange = async () => {
    reset();
    const data = await mutateAsync();
    if (isSuccess) {
      setFavourite(data.data.favourited);
      changesSaved();      
    } else if (isError) {
      genericError();
    }
  };

  return (favourite ? 
    <IconButton onClick={async ()=> { await handleChange(); }}><Favorite sx={{ color:'red' }}/></IconButton> : 
    <IconButton onClick={async ()=> { await handleChange(); }}><FavoriteBorder/></IconButton>
  );
};