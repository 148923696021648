import React, { FC, useContext } from 'react';
import { Box, Typography, Divider } from '@mui/material';

import { OrderGrid } from './BasketGrid/BasketTable';
import { CompanyContext } from '../../../contexts';


export const Basket: React.FC = () => {
  const { company } = useContext(CompanyContext);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Typography variant="h3" color="text.primary" sx={{ m: 2 }}>
          Koszyk
        </Typography>
      </Box>
      <Divider>
        <Typography variant="h4" color="text.primary" sx={{ m: 2 }}>
          {company ? company.name : 'Pozycje'}
        </Typography>
      </Divider>
      <OrderGrid/>
    </Box>
  );
};