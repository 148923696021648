import React, { FC, useContext, useEffect, useState } from 'react';
import { Box, Typography, Pagination, Stack, Breadcrumbs, Button } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useLocation } from 'react-router';

import SideBar from './catalogs/SideBar/SideBar';
import { useItemsList } from '../../../hooks/useSearchItemsList';
import noImage from '../../../layouts/images/noImage.png';
import ItemsRender from './components/ItemsRender';
import Loader from '../../../layouts/components/BodyLoading/Loader';
import SearchInput from './catalogs/Search/Search';
import SearchingError from './catalogs/Search/SearchingError';
import { useCatalogsList } from '../../../hooks/useCatalogsList';
import { useCategoryList } from '../../../hooks/useCategoryList';
import { LayoutContext, MediaQueryContext } from '../../../contexts';


export const Catalog: FC = () => {
  const location = useLocation();

  const limit: number = 20;

  const { query, setQuery, catalog, setCatalog, category, setCategory, currentPage, setCurrentPage } =
    useContext(LayoutContext);

  const { isLargerThanMd, isLargerThanSm } = useContext(MediaQueryContext);

  const [system_number, setSystem_number] = useState('');

  const { isLoadingCatalog } = useCatalogsList();
  const { isLoadingCategory } = useCategoryList();
  const { itemsList, count, isError, isLoading, refetch } = useItemsList(
    (currentPage - 1) * limit,
    limit,
    query,
    system_number,
    category?.id ? category.id : '',
    catalog?.id ? catalog.id : ''
  );

  useEffect(()=>{
    refetch();
  }, [location]);

  const isLoadingAll = (
    isLoadingCatalog: boolean,
    isLoadingCategory: boolean,
    isLoading: boolean
  ) => {
    if (!isLoadingCatalog && !isLoadingCategory) {
      return false;
    } else if (isLoadingCatalog || isLoadingCategory || isLoading) {
      return true;
    }
  };

  const handleChange = (value: string) => {
    setQuery(value);
    setCurrentPage(1);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  if (isLoadingAll(isLoadingCatalog, isLoadingCategory, isLoading)) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Loader/>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', px: 2, pt: 2 }}>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', m: 2, width: '100%' }}
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          alignItems="center"
          justifyContent={{ xs: 'center', sm: 'space-between' }}
          spacing={{ xs: 1, sm: 2 }}
        >
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small"/>}
            aria-label="breadcrumb"
          >
            <Button  sx={{ fontSize: { xs: 'h4.fontSize', md: 'h3.fontSize' }, color: 'black',
              '&:hover': {
                backgroundColor: 'transparent',
              } }}  onClick={() => {
              setCatalog(undefined);
              setCategory(undefined);
              setCurrentPage(1);
              setQuery('');}}>
                Katalogi
            </Button>
            <Button sx={{ fontSize: { xs: 'h5.fontSize', md: 'h4.fontSize' }, color: 'black', '&:hover': {
              backgroundColor: 'transparent' } }} onClick={() => {
              setCategory(undefined);
              setCurrentPage(1);
              setQuery('');}}>
              {catalog?.name || 'Wszystkie'}
            </Button>
            {category !== undefined && catalog !== undefined && (
              <Button disabled sx={{ fontSize: { xs: 'h5.fontSize', md: 'h4.fontSize' },'&:disabled': {
                color: 'black', 
              } }}>
                {category?.name}
              </Button>
            )}
          </Breadcrumbs> 
          <SearchInput query={query} handleChang={handleChange}/>
        </Stack>
        <Box sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr', marginTop: isLargerThanSm ? '8px' : '4px' }}>
          {isLargerThanMd && (
            <SideBar setCatalog={setCatalog} setCategory={setCategory}/>
          )}
          {isLoading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '80vh',
                width: '100%',
              }}
            >
              <Loader/>
            </Box>
          )}
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'stretch',
                gap: '20px',
                pt: 2,
              }}
            >
              {!isLoading &&
                itemsList.map((item) => {
                  return (
                    <ItemsRender
                      key={item.id}
                      id={item.id}
                      image={item.image ? item.image : noImage}
                      alt={item.name}
                      title={item.name}
                      price={item.price}
                      quanity={item.quantity}
                      favourite={item.favourite}
                      refetch={refetch}
                    />
                  );
                })}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '20px 0px',
              }}
            >
              {!isLoading && count !== 0 ? (
                <Pagination
                  count={Math.ceil(count / limit)}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              ) : (
                !isLoading && <SearchingError/>
              )}
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
