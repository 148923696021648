import { useQuery } from '@tanstack/react-query';
import React, { useContext } from 'react';

import { FavouriteItemQuery } from '../clients/catalogs/favouriteItems/favouriteItems';
import { cacheKeys } from '../config';
import { UserContext } from '../contexts';


export const useFavoruiteUserItemsList = ( 
  offset: number= 0, 
  limit: number =20, 
) => {
  const { user } = useContext(UserContext);
  

  const { data: { data } = {}, isLoading, isError, refetch } = useQuery([`${cacheKeys.favouriteItems.favouriteItems}-${offset}-${limit}-${user?.id}`], 
    ()=>FavouriteItemQuery.FavouriteItemList(limit, 
      offset
    ),
    {
      enabled: !!user,
    },
  );

  return ({
    itemsList: data?.results || [],
    count: data?.count || 0,
    isLoading,
    isError,
    refetch: refetch,
  });
};