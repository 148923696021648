import React from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';


const Footer: React.FC = () => {
  const phoneNumber = '+48 123 123 123';
  const emailAddress = 'biuro@AddAllHoreca.pl';
  return (
    <>
      <Box sx={{ backgroundColor: '#c2b280', paddingBottom: '20px' }}>
        <Grid container spacing={3} mt={15} justifyContent="space-around">
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="h4" sx={{ margin: '15px' }}>
              {' '}
              DANE KONTAKTOWE
            </Typography>
            <Typography variant="h6" sx={{ margin: '15px' }}>
              ADD ALL Adam Rękawek
            </Typography>
            <Typography variant="h6" sx={{ margin: '15px' }}>
              ul. Saneczkowa 12
            </Typography>
            <Typography variant="h6" sx={{ margin: '15px' }}>
              05-520 Konstancin-Jeziorna
            </Typography>
            <Typography variant="h6" sx={{ margin: '15px' }}>
              Telefon:{' '}
              <a
                href={`tel:${phoneNumber}`}
                style={{ textDecoration: 'none', color: 'black' }}
              >
                {phoneNumber}
              </a>
            </Typography>
            <Typography variant="h6" sx={{ margin: '15px' }}>
              Pon-Pt 8:00-16:00
            </Typography>
            <Typography variant="h6" sx={{ margin: '15px' }}>
              E-mail:{' '}
              <a
                href={`mailto:${emailAddress}`}
                style={{ textDecoration: 'none', color: 'black' }}
              >
                {emailAddress}
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="h4" sx={{ margin: '15px' }}>
              {' '}
              ZAKUPY ONLINE
            </Typography>
            <Typography variant="h6" sx={{ margin: '15px' }}>
              Dostawa
            </Typography>
            <Typography variant="h6" sx={{ margin: '15px' }}>
              <Link style={{ textDecoration: 'none', color: 'black' }} to="/Regulamin">Regulamin</Link>
            </Typography>
            <Typography variant="h6" sx={{ margin: '15px' }}>
              <Link style={{ textDecoration: 'none', color: 'black' }} to="/Polityka prywatnosci">Polityka Prywatności</Link>
            </Typography>
            <Typography variant="h6" sx={{ margin: '15px' }}>
              <Link style={{ textDecoration: 'none', color: 'black' }} to="/Reklamacje i zwroty">Reklamacje i zwroty</Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="h4" sx={{ margin: '15px' }}>
              {' '}
              Formularz Kontaktowy
            </Typography>
            <form>
              <Grid container spacing={1}>
                <Grid xs={12} item>
                  <TextField
                    type="email"
                    label="Email"
                    placeholder="Podaj swój email"
                    variant="outlined"
                    required
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: '#A9965B',
                        },
                      },
                      '& .MuiInputLabel-outlined': {
                        color: '#A9965B',
                      },
                      '&:focus-within .MuiInputLabel-outlined': {
                        color: 'black',
                      },
                    }}
                  />
                </Grid>
                <Grid xs={12} item>
                  <TextField
                    type="number"
                    label="Telefon"
                    placeholder="Podaj swój numer telefon"
                    variant="outlined"
                    fullWidth
                    required
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: '#A9965B',
                        },
                      },
                      '& .MuiInputLabel-outlined': {
                        color: '#A9965B',
                      },
                      '&:focus-within .MuiInputLabel-outlined': {
                        color: 'black',
                      },
                    }}
                  />
                </Grid>
                <Grid xs={12} item>
                  <TextField
                    label="Wiadomość"
                    multiline
                    rows={4}
                    placeholder="Napisz swoją wiadomość"
                    variant="outlined"
                    fullWidth
                    required
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: '#A9965B',
                        },
                      },
                      '& .MuiInputLabel-outlined': {
                        color: '#A9965B',
                      },
                      '&:focus-within .MuiInputLabel-outlined': {
                        color: 'black',
                      },
                    }}
                  />
                </Grid>
                <Grid xs={12} item>
                  <Button
                    type="submit"
                    fullWidth
                    sx={{
                      backgroundColor: '#A9965B',
                      color: 'black',
                      '&:hover': {
                        backgroundColor: '#AC9653',
                      },
                    }}
                  >
                    {' '}
                    Wyślij
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Footer;
