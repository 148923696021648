export const cacheKeys = {
  user: {
    userLogin: 'userLogin',
    userRegister: 'userRegister',
    userInvitation: 'userInvitation',
  },
  category:{
    categoryList:'categoryList',
  },
  catalogs:{
    catalogsList:'catalogsList',
  },
  items:{
    itemsList:'itemsList',
  },
  company:{
    companyList:'companyList',
  },
  offer:{
    offerList:'offerList',
  },
  order:{
    orderList:'OrderList',
  },
  orderItems:{
    orderItemsList:'OrderItemsList',
  },
  favouriteItems:{
    favouriteItems:'favouriteItems',
  },
  invoice:{
    InvoiceList:'InvoiceList',
  },
  invoiceItem:{
    InvoiceItemList:'InvoiceItemList',
  },
};
  
export const routes = {
  home: '/',
  invitation: (token:string = ':invitationId') => `/dolacz/${token}`,
  resetPassword: (token:string = ':resetingId') => `/zresetuj-haslo/${token}`,
  register: '/zarejestruj-się',
  catalogs: '/produkty',
  login: '/logowanie',
  impersonate: (token:string = ':token') => `/impersonate/${token}`,
    
  //authenticated
  orders: '/zamówienia',
  offers:'/oferty',
  basket: '/koszyk',
  ordersItems: (id:string = ':id') => `/zamówienia/${id}`,
  invoices: '/faktury',
  invoicesItems: (id:string = ':id') => `/faktury/${id}`,
  companies: '/firmy',
  company: (id:string = ':id') => `/firmy/${id}`,
  myAccount: '/mój-profil',
  settings: '/ustawienia',
  team: '/zespół',
  notifications:'/powiadomienia',
  turtorial: '/poradnik',
  help: '/pomoc-techniczna',
  deletedOffers: '/usunięte-oferty',
  addOffer: '/dodaj-ofertę',
  favouriteItems: '/najczęściej-wybierane',
  favourite: '/ulubione-produkty',
  manageRestaurants: '/zarządzaj-restauracjami',

};
  
export type Routes = keyof typeof routes;
  
export const routeBreadcrumbs: Record<Routes, Routes[]> = {
  home: ['home'],
  impersonate: ['home', 'impersonate'],
  offers: ['home', 'offers'],
  basket: ['home', 'basket'],
  catalogs: ['catalogs'],
  login: ['login'],
  register: ['register'],
  invitation: ['invitation'],
  resetPassword: ['resetPassword'],
  orders: ['orders'],
  ordersItems: ['orders', 'ordersItems'],
  invoices: ['invoices'],
  invoicesItems: ['invoices', 'invoicesItems'],
  companies: ['companies'],
  company: ['companies', 'company'],
  myAccount: ['myAccount'],
  team: ['team'],
  settings:['settings'],
  notifications: ['notifications'],
  turtorial:['home', 'turtorial'],
  help:['home','turtorial', 'help'],
  deletedOffers:['home', 'deletedOffers'],
  addOffer:['home', 'addOffer'],
  favouriteItems:['home', 'favouriteItems'],
  favourite:['home', 'favourite'],
  manageRestaurants:['home', 'manageRestaurants'],
};

export const PageSize =[ 10, 25, 50, 100 ];