import { AxiosPromise, AxiosResponse } from 'axios';

import { request } from '../baserequest';
import { paginatedresult } from '../../types/pagination';
import { Offer, PaginationProps } from './types';


const APIURL = process.env.REACT_APP_SKALO_API;

const OfferList = (Props:PaginationProps & { active:boolean }):AxiosPromise<paginatedresult<Offer>> => {
  return request({
    options:{
      url: `${APIURL}offer/`,
      method: 'GET',
      params:{
        limit: Props.limit,
        offset: Props.offset,
        active: Props.active,
      },
    },
  }).then((response) => ({
    ...response,
    data: {
      ...response.data,
      results: response.data.results,
    },
  }));
};

const OfferUpdate = (id:number | string, data:Partial<Offer> ): Promise<AxiosResponse<Offer>> => {
  return request({
    options:{
      url: `${APIURL}offer/${id}/`,
      method: 'PUT',
      data: data,
    },
  });
};


const OfferDelete = (id:number | string): Promise<AxiosResponse> => {
  return request({
    options:{
      url: `${APIURL}offer/${id}/`,
      method: 'DELETE',
    },
  });
};
  

export const OffersQuery = { OfferList, OfferUpdate, OfferDelete };
