import { useQuery } from '@tanstack/react-query';
import React, { useContext } from 'react';

import { ItemQuery } from '../clients/catalogs/items/items';
import { cacheKeys } from '../config';
import { CompanyContext } from '../contexts';


export const useFavoruiteItemsList = ( 
  offset: number, 
  limit: number,
  query:string
) => {
  const { company } = useContext(CompanyContext);
  

  const { data: { data } = {}, isLoading, isError } = useQuery([`${cacheKeys.items.itemsList}-${offset}-${limit}-${company?.id}`, query], 
    ()=>ItemQuery.FavouriteItemsList(limit, 
      offset, 
      company === undefined ? undefined : company.id,
      query
    ),
    {
      enabled: !!company,
    },
  );

  return ({
    itemsList: data?.results || [],
    count: data?.count || 0,
    isLoading,
    isError,
  });
};