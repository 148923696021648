import React, { FC } from 'react';
import { InputBase, styled } from '@mui/material';


const Search = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  padding: '0 10px',
  borderRadius: theme.shape.borderRadius,
  width:300,
}));


type Props = {
    handleChang: any,
    query:string | null
}

const SearchInput: FC<Props> = ({ handleChang, query }) => {
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChang(event.target.value);
  };
  
  return (
    <Search sx={{ boxShadow: 2 }}>
      <InputBase
        placeholder="Szukaj..."
        type="text"
        value={query}
        onChange={handleChange}
        sx={{
          width:'100%',
        }}
      />
    </Search>
  );
};
  
export default SearchInput;
  