import { useQuery } from '@tanstack/react-query';

import { OffersQuery } from '../../clients/offers/offers';
import { cacheKeys } from '../../config';


type Props = {
  page:number,
  pageSize:number,
  active:boolean
}

export const useOfferList = (Prop:Props) => {
  const offset = Prop.page * Prop.pageSize;
  const { data: { data }={}, isLoading, refetch } = useQuery([`${cacheKeys.offer.offerList}-${offset}-${Prop.pageSize}-${Prop.active}`], 
    ()=>OffersQuery.OfferList({ limit:Prop.pageSize, offset:offset, active:Prop.active }),
    {
      enabled: true,
    },);

  return ({
    data:data?.results || [],
    count: data?.count || 0,
    isLoading,
    refetch,
  });
};