import React, { FC, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { DataGridPro, DataGridProProps, GridColDef } from '@mui/x-data-grid-pro';

import { PageSize } from '../../../../config';
import { Invoice } from '../../../../clients/invoice/types';
import { useInvoiceItemList } from '../../../../hooks/Invoice/useInvoiceItem';


function DetailPanelContent({ row }: any) {
  const { data, isLoading } = useInvoiceItemList({ invoice__id:row?.id });

  return (
    <Stack
      sx={{ py: 2, height: '100%', boxSizing: 'border-box' }}
      direction="column"
    >
      <Paper sx={{ flex: 1, mx: 'auto', width: '90%', p: 1 }}>
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <Typography variant="h6">{`Zamówienie nr. ${row.numer_faktury}`}</Typography>
          <Grid container>
            <Grid item md={6}>
              <Typography variant="body2">
                  Informacje o fakturze
              </Typography>
              <Typography variant="body1">{row.company.name}</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant="body2"  align="right">
                  Sprzedawca
              </Typography>
              <Typography variant="body1" align="right">
                Add All
              </Typography>
            </Grid>
          </Grid>
          <DataGridPro
            density="compact"
            columns={[
              { field: 'item', headerName: 'Produkty', valueGetter: ({ row }) => row.item.name, width:600 },
              {
                field: 'system_number',
                headerName: 'Kod Produktu',
                align: 'center',
                valueGetter: ({ row }) => row.item.system_number,
                headerAlign:'center',
                width: 120,
              },
              {
                field: 'quanity',
                headerName: 'Ilość',
                align: 'center',
                width: 100,
                headerAlign:'center',
              },
              { field: 'cena_netto', headerName: 'Cena Netto', width: 120, align: 'center', headerAlign:'center' },
              { field: 'cena_brutto', headerName: 'Cena Brutto', width: 120, align: 'center', headerAlign:'center' },
              {
                field: 'total_netto',
                headerName: 'Wartość Netto',
                type: 'number',
                valueGetter: ({ row }) => parseFloat(row.quanity) * parseFloat(row.cena_netto),
                width: 160,
                headerAlign:'center',
                align: 'center',
              },
              {
                field: 'total_brutto',
                headerName: 'Wartość Brutto',
                type: 'number',
                valueGetter: ({ row }) => parseFloat(row.quanity) * parseFloat(row.cena_brutto),
                width: 160,
                headerAlign:'center',
                align: 'center',
              },
            ]}
            rows={data}
            loading={isLoading}
            sx={{ flex: 1 }}
            hideFooter
          />
        </Stack>
      </Paper>
    </Stack>
  );
}

type Props = {
    isLoading:boolean,
    data: Invoice[]
    count: number,
    setPaginationModel:any,
    paginationModel: any,
}

export const InvoiceGrid:FC<Props> = ({ data, isLoading, setPaginationModel, count, paginationModel }) => {

      
  const columns: GridColDef[] = [
    { field: 'numer_faktury', headerName: 'Numer Faktury', width: 200, align:'center' },
    { field: 'date', type: 'date', headerName: 'Data Wystawienia', width: 200, align:'center' },
    { field: 'netto', headerName: 'Wartość Netto', width: 150, align:'center' },
    { field: 'brutto', headerName: 'Wartość Brutto', width: 150, align:'center' },
    { field: 'type', headerName: 'Typ Faktury', width: 150, align:'center' },
    {
      field: 'name',
      type: 'number',
      headerName: 'Wartość VAT',
      width: 150,
      align:'center',
      valueGetter: ({ row }) => {
        const VAT = row.brutto - row.netto;
        return VAT ;
      },
    },
  ];

  const getDetailPanelContent = React.useCallback<
  NonNullable<DataGridProProps['getDetailPanelContent']>
 >(({ row }) => <DetailPanelContent row={row}/>, []);

  const getDetailPanelHeight = React.useCallback(() => 600, []);

  return (
    <Box sx={{ width: '100%' }}>
      <DataGridPro
        columns={columns}
        rows={data}
        autoHeight={true}
        loading={isLoading}
        rowCount={count}
        pagination
        pageSizeOptions={PageSize}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        disableRowSelectionOnClick
        disableMultipleRowSelection
        disableMultipleColumnsSorting
        disableMultipleColumnsFiltering
        disableColumnMenu
        disableChildrenSorting
        disableChildrenFiltering
      />
    </Box>
  );

};