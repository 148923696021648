import React, { FC, useContext, useEffect } from 'react';
import { useParams } from 'react-router';

import { UserContext } from '../../../contexts';
import  Loader  from '../../../layouts/components/BodyLoading/Loader';


export const Impersonate:FC = () => {
  const { setJWT, JWT } = useContext(UserContext);
  const params = useParams();
  
  useEffect(()=>{
    localStorage.removeItem('JWT');
    setJWT(params.token);
  }, [JWT, setJWT]);

  return (
    <Loader/>
  );
};