import { FC } from 'react';
import {
  Box,
  List,
} from '@mui/material';

import { CatalogList } from '../CatalogList/CatalogList'; 


type Props = {
  setCategory: any,
  setCatalog: any
}

const SideBar: FC<Props> = ({ setCategory, setCatalog }) => {

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <List sx={{ width:280 }}>
        <CatalogList setCatalog={setCatalog} setCategory={setCategory}/>
      </List>
    </Box>
  );
};

export default SideBar;
