import { Box } from '@mui/material';


const Profile = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box flex={6}>Profile</Box>
    </Box>
  );
};

export default Profile;
