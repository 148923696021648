import React, { FC, createContext, useState, useCallback, useEffect, useContext, useMemo } from 'react';

import { DialogProduct } from '../../layouts/components/Dialog/ProductDialog/ProductDialog';
import { OrderConfirmationDialog } from '../../layouts/components/Dialog/confirmationDialog/OrderConfirmation';
import { ContextProps, OrderItem } from './types';


const defaultContext: ContextProps = {
  orderDialogState:false,
  setOrderDialogState: () => null,
  productDialogState: {
    open:false,
    productId: undefined,
    image: null,
    name:undefined,
    alt:undefined,
  },
  setproductDialogState: () => null,
  closeProductDialog: () => null,
  closeOrderDialog: ()=> null,
};

export const DialogContext = createContext(defaultContext);

interface Props {
  children: React.ReactNode;
}

export const DialogContextProvider: FC<Props> = ({ children }) => {

  const [productDialogState, setproductDialogState] = useState(defaultContext.productDialogState);
  const [orderDialogState, setOrderDialogState] = useState(defaultContext.orderDialogState);


  const closeProductDialog = useCallback(()=>{
    setproductDialogState({
      open:false,
      productId:undefined,
      image: null,
      name:undefined,
      alt:undefined,
    });
  }, [productDialogState]);

  const closeOrderDialog = useCallback(()=>{
    setOrderDialogState(false);
  }, []);

  const ProductDialog = () => {
    return (
      <DialogProduct/>
    );
  };


  return (
    <DialogContext.Provider
      value={{
        setproductDialogState,
        productDialogState,
        closeProductDialog,
        orderDialogState,
        setOrderDialogState,
        closeOrderDialog,
      }}
    >
      <>
        <ProductDialog/>
        <OrderConfirmationDialog/>
        {children}
      </>
    </DialogContext.Provider>
  );
};
