import React, { ReactNode, Fragment } from 'react';

import { Country } from '../../../types/Country';


export const dictionary = {
  no: 'Nie',
  yes: 'Tak',

  switchToLightMode: 'Przełącz na tryb jasny',
  switchToDarkMode: 'Przełącz na tryb jasny',

  notFound: 'Nie znaleziono',
  noPermission: 'Nie masz pozwolenia na przeglądanie tej strony',
  somethingWentWrong: 'Coś poszło nie tak!',
  noResults: 'Brak wyników',

  login: {
    title: 'Zaloguj się',
    buttonLogin: 'Zaloguj się',
    buttonLoginWithGoogle: 'Zaloguj się używając Google',
    buttonLoginWithMicrosoft: 'Zaloguj się używając Microsoft',
  },

  signup: {
    title: 'Zarejestruj się',
    buttonLogin: 'Zaloguj się',
    buttonSignup: 'Zarejestruj się',
    buttonSignUpWithGoogle: 'Zarejestruj się używając Google',
    buttonSignUpWithMicrosoft: 'Zarejestruj się używając Microsoft',
    termsOfService: 'Warunki Usługi',
    privacyPolicy: 'Politykę Prywatności',
    acceptTermsAndConditions: (terms: ReactNode, privacy: ReactNode) => <Fragment>Rejestrując konto w AR Solutions akceptujesz {terms} oraz {privacy}</Fragment>,
  },

  resetPassword: {
    title: 'Zresetuj Hasło',
    buttonLogin: 'Zaloguj się',
    buttonSignup: 'Zarejestruj się',
    buttonReset: 'Zresetuj hasło',
    passwordResetEmailSent: 'Wysłano wiadomość e-mail resetującą hasło',
  },

  user: {
    title: 'Użytkownik',
    detailsTitle: 'Informacje',
    typeCustomerLabel: 'Klient',
    typeAdminLabel: 'Administrator',

    create: {
      title: 'Stwórz użytkownika',
      buttonCreate: 'Stwórz użytkownika',
    },

    edit: {
      buttonEdit: 'Edytuj Dane Użytkownika',
      buttonDelete: 'Usuń Użytkownika',
      deleteConfirmation: 'Czy na pewno chcesz usunąć tego Użytkownika?',
    },

    companyLabel: 'Firma',
    jobTitleLabel: 'Stanowisko',
    adminLabel: 'Administrator',
  },

  invite: {
    dialog: {
      title: 'Zaproś członków zespołu',
      placeholderEmail: 'Adresy email, oddzielone przecinkiem',
      buttonInvite: 'Wyślij zaproszenie',
      invitationSentInfo: (email: string) => `Zaproszenie na adres ${email} wysłane`,
    },
    title: 'Zaproszenie do Zespołu',
    subtitle: (company?: string) => company
      ? `Zaproszono Cię do dołączenia do zespołu w ${company}`
      : 'Zaproszono Cię do dołączenia do zespołu',
    buttonAcceptInvitation: 'Załóż konto',
    inviteButton: 'Zaproś Członka Zespołu',
  },

  users: {
    title: 'Użytkownicy',
  },

  menu: {
    login: 'Zaloguj się',
    logout: 'Wyloguj się',
    signUp: 'Zarejestruj się',
    dashboard: 'Panel Główny',
    users: 'Użytkownicy',
    products: 'Produkty',
    report: 'Raport',
    savingList: 'Lista Oszczędności',
    user: {
      myAccount: 'Moje Konto',
    },
    team: 'Zespół',
    companies: 'Firmy',
    help: 'Pomoc',
    invoices: 'Baza faktur',
    rating: 'Wystaw Ocenę',
  },

  auth: {
    validations: {
      userWithThisEmailAlreadyExists: 'Użytkownik o tym adresie e-mail już istnieje',
      passwordsDoNotMatch: 'Hasła różnią się',
      emailOrPasswordWrong: 'Nieprawidłowy e-mail lub hasło',
      thePasswordMustBeAtLeast8CharactersLong: 'Hasło musi zawierać min. 8 znaków',
    },

    impersonate: {
      noToken: 'Token nie istnieje',
      invalidToken: 'Token jest nieprawidłowy lub wygasł',
    },

    firebase: {
      errors: {
        'auth/account-exists-with-different-credential': 'To konto zostało już zarejestrowane z innym dostawcą logowania',
      },
    },
    divider: 'lub',
  },

  team: {
    title: 'Zespół',
    unknownLabel: 'Nieznany',
    detailsLabel: 'Szczegóły',
    positionLabel: 'Stanowisko',
  },

  report: {
    title: 'Raport',
    totalCost: 'Koszty całkowite',
    potentialSavings: 'Potencjalne oszczędności',
    realSavings: 'Realne oszczędności',

    saveUpTo: 'Zaoszczędź do',
    openList: 'Pokaż listę',

    topTenLabel: 'Top 10 produktów według oszczędności',

    filterLabels: {
      timeRange: 'Zakres czasu',
      month: 'Miesiąc',
      year: 'Rok',

      productGroup: 'Grupa produktów',
    },

    cardItem: {
      cost: 'Kosztów',
      totalNetCost: 'Obecny Koszt',
      price: 'Najniższa cena jednostkowa',
      totalSavings: 'Całkowite oszczędności',
    },

    noDataAvailable: 'Brak dostępnych danych',
    currency: 'zł',
  },

  dataGrid: {
    toolbar: {
      filters: {
        search: {
          label: 'Szukaj',
          placeholder: 'Wpisz szukaną frazę...',
        },
        supplier: {
          label: 'Dostawca',
          placeholder: 'Wybierz dostawcę',
        },
        partner: {
          label: 'Partner',
          placeholder: 'Wybierz partnera',
        },
        group: {
          label: 'Grupa produktów',
          placeholder: 'Wybierz grupę',
        },
        datePicker: {
          from: 'z',
          to: 'do',
        },
        categories: {
          emptyChoice: 'Pusta wartość',
          categoryPlaceholder: 'Wybierz kategorię',
          category1: 'Kategoria 1',
          category2: 'Kategoria 2',
          category3: 'Kategoria 3',
        },

        operators: {
          contains: 'Zawiera',
          equals: 'Równa się',
          isEmpty: 'Brak',
          startsWith: 'Zaczyna się na',
          endsWith: 'Kończy się na',
          not: 'To nie',
          is: 'To',
          after: 'Po',
          onOrAfter: 'Po włącznie',
          before: 'Przed',
          onOrBefore: 'Przed włącznie',
          between: 'Pomiędzy',
          betweenInclusive: 'Pomiędzy włącznie',
        },
      },
    },
    columnMenu: {
      hideAllColumns: 'Ukryj wszystko',
      showAllColumns: 'Pokaż wszystko',
    },
  },

  products: {
    productsList: 'Lista produktów',
    addProducts: 'Dodaj produkty',
    import: 'Import',
    chooseFile: 'Wybierz plik',
    csvUpload: 'Dodaj produkty przez CSV',
    manualUpload: 'Dodawanie manualne',
    addManually: 'Dodaj manualnie',
    importDescription : 'Przeciągnij i upuść plik Excel lub',
    manualUploadDescription : 'Dodaj produkty wprowadzając dane manualnie',

    importDialog: {
      goToPreviousStepTooltip: 'Wróć',
      importCsv: 'Import CSV',
      initialDragDropPrompt : 'Przeciągnij i upuść plik Excel lub kliknij i wybierz plik',
      activeDragDropPrompt: 'Upuść plik CSV tutaj...',
      goBackButton: 'Z powrotem',
      nextButton: 'Wybierz kolumny',
      rawFileContentsHeading: 'Surowa zawartość pliku',
      previewImportHeading: 'Podgląd importu',
      dataHasHeadersCheckbox: 'Dane mają nagłówki',
      previewLoadingStatus: 'Ładowanie podglądu...',
      dragTargetPlaceholder: 'Przeciągnij kolumnę tutaj',
      finishButton: 'Powrót do storny głównej',
    },

    importFields: {
      id: 'ID',
      externalId: 'LP2',
      title: 'Oczyszczona',
      quantity: 'Ilość kupowanego towaru',
      unitOfMeasurement: 'J.m.',
      priceNet: 'Cena netto',
      supplier: 'Dostawca',
      invoiceNumber: 'Numer Faktury',
      invoiceDate: 'Data',
      vatPercentageValue: 'VAT %',

      errors: {
        fileRows: (errors: number[] | undefined) => `Rzędy numer: ${errors?.join(', ') || ''}`,
      },
    },

    manualUploadDialog: {
      title: 'Dodawanie produktów manualne',
    },
    editDialog: {
      title: 'Edytowanie produktu',
    },

    columns: {
      id: '',
      title: 'Produkt',
      systemName: 'Nazwa Systemowa',
      productGroup: 'Grupa produktu',
      quantity: 'Ilość',
      unitOfMeasurement: 'Jednostka',
      priceNet: 'Cena Netto',
      netCost: 'Koszt Netto',
      priceGross: 'Cena brutto',
      supplier: 'Dostawca',
      categories: 'Kategorie',
      invoiceNumber: 'Numer Faktury',
      invoiceDate: 'Data Wystawienia',
      vatPercentageValue: 'VAT %',
      totalVat: 'Koszt VAT',
      converter: 'Konwerter',
      converterCount: 'Sztuki Konwerter',
      converterPrice: 'Cena Konwerter',
    },

    groups: {
      emptyValue: 'Pusta wartość',
      chemistry: 'Chemia',
      meat: 'Mięso',
      vegetables: 'Warzywa',
      dry: 'Suche',
      dairy: 'Nabiał',
      drinks: 'Napoje',
      other: 'Inne',
    },
  },

  savingsList: {
    title: 'Lista oszczędności',

    columns: {
      product: 'Produkt',
      productGroup: 'Grupa produktu',
      currentPrice: 'Obecna cena',
      currentSupplier: 'Obecny Dostawca',
      competitivePrice: 'Cena innego dostawcy',
      competitiveSupplier: 'Inny Dostawca',
      totalSavings: 'Całkowite oszczędności',
      invoiceNumber: 'Numer Faktury',
      invoiceDate: 'Data Wystawienia',
      clientOldPrice: 'Cena Stara',
      quantity: 'Ilość',
      netCost: 'Koszt Netto',
      systemName: 'Nazwa Systemowa',
      includedInRealSavings: 'Włącz',
      includedInRealSavingsTooltip: 'Uwzględnij produkt w realnych oszczędnościach',
    },
  },

  invoices: {
    title: 'Baza faktur',

    columns: {
      invoiceNumber: 'Numer Faktury',
      productTitle: 'Tytuł produktu',
      quantity: 'Ilość kupionego towaru',
      unit: 'Jednostka',
      priceNet: 'Cena Netto',
      valueNet: 'Wartość netto',
      percentVat: 'VAT %',
      amountVat: 'Kwota VAT',
      amountGross: 'Kwota Brutto',
      productGroup: 'Grupa',
      supplier: 'Dostawca',
      invoiceDate: 'Data',
      partner: 'Partner',
      categories: 'Kategorie',
      converter: 'Konwerter',
      priceConverter: 'Cena Konwerter',
    },
  },

  forms: {
    fieldSearch: 'Szukaj',
    fieldEmail: 'E-mail',
    fieldPassword: 'Hasło',
    fieldPhone: 'Numer Telefonu',
    fieldCompany: 'Firma',

    manualUpload: {
      invoiceNumber: 'Numer faktury',
      invoiceDate: 'Data wystawienia',
      invoiceSupplier: 'Dostawca',
      productName: 'Nazwa Produktu',
      productGroup: 'Grupa Produktu',
      productNetPrice: 'Cena Netto',
      quantity: 'Ilość',
      unitOfMeasure: 'Jednostka',
      vatPercentage: 'VAT%',
      vatTotal: 'Koszt VAT',
      priceGross: 'Cena Brutto',
      converter: 'Konwerter',
      converterCount: 'Sztuki Konwerter',
      converterPrice: 'Cena Konwerter',
      addProduct: 'Dodaj kolejny produkt w tej fakturze',
      saveInvoice: 'Zapisz fakturę',
      currency: 'zł',
      saveProduct: 'Zapisz produkt',
    },

    signup: {
      repeatPassword: 'Powtórz Hasło',
    },

    user: {
      fieldName: 'Imię',
      fieldLastName: 'Nazwisko',
      fieldPosition: 'Stanowisko',
      fieldAdmin: 'Administrator',
      fieldPicture: 'Przekaż plik ze zdjęciem',
      fieldType: 'Rodzaj Użytkownika',
      fieldEmailDisabledHelper: 'Skontaktuj się z pomocą techniczną, aby zmienić adres e-mail.',
    },

    validations: {
      required: 'To pole jest wymagane',
      invalidEmail: 'Nieprawidłowy adres e-mail',
      invalidPhone: 'Nieprawidłowy numer telefonu',
      invalidPassword: 'Hasło jest nieprawidłowe. Wymagana jest co najmniej jedna cyfra',
      invalidQuantity: 'Nieprawidłowa ilość',
      invalidFileSize: (max: string) => `Plik jest za duży. Maksymalny rozmiar to ${max}`,
      invalidFileType: 'Typ pliku jest nieprawidłowy',
      minLength: (length: number | string) => `Co najmniej ${length} znaków jest wymagane`,
      maxLength: (length: number | string) => `Przekroczono maksymalną ilość znaków (${length})`,
      oneOrMoreFieldsAreIncorrect: 'Co najmniej jedno pole jest nieprawidłowe.',
      memberInvitationAllEmailsValid: 'Co najmniej jeden adres e-mail jest nieprawidłowy.',
    },
    attach: 'Dołącz',
    saveChanges: 'Zapisz zmiany',
  },

  dialogs: {
    defaultTitle: 'Czy na pewno chcesz kontynuować?',
    defaultContent: 'To działanie jest nieodwracalne.',
    no: 'Nie',
    yes: 'Tak',
    cancel: 'Anuluj',
    close: 'Zamknij',
    buttonCancel: 'Anuluj',
    removeInvoiceItemTitle: 'Usuwanie Produktu',
    removeInvoiceItemDescription: 'Czy na pewno chcesz usunąć tę pozycję z faktury?',
    delete: 'Tak, usuń',
    changesSaved: 'Zmiany zapisane!',
    csvChangesSaved: 'Zmiany zapisane! Proszę poczekaj 5 do 10 minut aż system przeliczy oszczędności',
  },

  routes: {
    companies: 'Firmy',
    createCompany: 'Stwórz Firmę',
    createUser: 'Stwórz Użytkownika',
    editCompany: (company: string) => `Edytuj ${company}`,
    editUser: (user: string) => `Edytuj ${user}`,
    home: 'Home',
    impersonateUser: 'Impersonate',
    login: 'Zaloguj się',
    invite: 'Zaproś',
    myAccount: 'Moje Konto',
    profile: 'Profil',
    resetPassword: 'Zresetuj Hasło',
    signup: 'Zarejestruj się',
    team: 'Zespół',
    users: 'Użytkownicy',
    products: 'Produkty',
    report: 'Raport',
    savingList: 'Lista Oszczędności',
    invoices: 'Baza faktur',
    rating: 'Oceny',
    addRating: 'Dodaj Ocenę',
    supplierInfo:'Opinie o dostawcy',
  },

  countries: {
    [Country.Andorra]: 'Andorra',
    [Country.UnitedArabEmirates]: 'United Arab Emirates',
    [Country.Afghanistan]: 'Afghanistan',
    [Country.AntiguaAndBarbuda]: 'Antigua and Barbuda',
    [Country.Anguilla]: 'Anguilla',
    [Country.Albania]: 'Albania',
    [Country.Armenia]: 'Armenia',
    [Country.Angola]: 'Angola',
    [Country.Antarctica]: 'Antarctica',
    [Country.Argentina]: 'Argentina',
    [Country.AmericanSamoa]: 'American Samoa',
    [Country.Austria]: 'Austria',
    [Country.Australia]: 'Australia',
    [Country.Aruba]: 'Aruba',
    [Country.AlandIslands]: 'Alland Islands',
    [Country.Azerbaijan]: 'Azerbaijan',
    [Country.BosniaAndHerzegovina]: 'Bosnia and Herzegovina',
    [Country.Barbados]: 'Barbados',
    [Country.Bangladesh]: 'Bangladesh',
    [Country.Belgium]: 'Belgium',
    [Country.BurkinaFaso]: 'Burkina Faso',
    [Country.Bulgaria]: 'Bulgaria',
    [Country.Bahrain]: 'Bahrain',
    [Country.Burundi]: 'Burundi',
    [Country.Benin]: 'Benin',
    [Country.BonaireSintEustatiusSaba]: 'Bonaire, Sint Eustatius and Saba',
    [Country.SaintBarthelemy]: 'Saint Barthelemy',
    [Country.Bermuda]: 'Bermuda',
    [Country.BruneiDarussalam]: 'Brunei Darussalam',
    [Country.Bolivia]: 'Bolivia',
    [Country.Brazil]: 'Brazil',
    [Country.Bahamas]: 'Bahamas',
    [Country.Bhutan]: 'Bhutan',
    [Country.BouvetIsland]: 'Bouvet Island',
    [Country.Botswana]: 'Botswana',
    [Country.Belarus]: 'Belarus',
    [Country.Belize]: 'Belize',
    [Country.Canada]: 'Canada',
    [Country.CocosKeelingIslands]: 'Cocos (Keeling) Islands',
    [Country.CentralAfricanRepublic]: 'Central African Republic',
    [Country.Congo]: 'Congo',
    [Country.CongoDemocraticRepublic]: 'Congo, Democratic Republic of the',
    [Country.Switzerland]: 'Switzerland',
    [Country.CoteDIvoire]: 'Cote d\'Ivoire',
    [Country.CookIslands]: 'Cook Islands',
    [Country.Chile]: 'Chile',
    [Country.Cameroon]: 'Cameroon',
    [Country.China]: 'China',
    [Country.Colombia]: 'Colombia',
    [Country.CostaRica]: 'Costa Rica',
    [Country.Cuba]: 'Cuba',
    [Country.CapeVerde]: 'Cape Verde',
    [Country.Curacao]: 'Curacao',
    [Country.ChristmasIsland]: 'Christmas Island',
    [Country.Cyprus]: 'Cyprus',
    [Country.CzechRepublic]: 'Czech Republic',
    [Country.Germany]: 'Germany',
    [Country.Djibouti]: 'Djibouti',
    [Country.Denmark]: 'Denmark',
    [Country.Dominica]: 'Dominica',
    [Country.DominicanRepublic]: 'Dominican Republic',
    [Country.Algeria]: 'Algeria',
    [Country.Ecuador]: 'Ecuador',
    [Country.Estonia]: 'Estonia',
    [Country.Egypt]: 'Egypt',
    [Country.WesternSahara]: 'Western Sahara',
    [Country.Eritrea]: 'Eritrea',
    [Country.Spain]: 'Spain',
    [Country.Ethiopia]: 'Ethiopia',
    [Country.Finland]: 'Finland',
    [Country.Fiji]: 'Fiji',
    [Country.FalklandIslands]: 'Falkland Islands (Malvinas)',
    [Country.Micronesia]: 'Micronesia, Federated States of',
    [Country.FaroeIslands]: 'Faroe Islands',
    [Country.France]: 'France',
    [Country.Gabon]: 'Gabon',
    [Country.UnitedKingdom]: 'United Kingdom',
    [Country.Grenada]: 'Grenada',
    [Country.Georgia]: 'Georgia',
    [Country.FrenchGuiana]: 'French Guiana',
    [Country.Guernsey]: 'Guernsey',
    [Country.Ghana]: 'Ghana',
    [Country.Gibraltar]: 'Gibraltar',
    [Country.Greenland]: 'Greenland',
    [Country.Gambia]: 'Gambia',
    [Country.Guinea]: 'Guinea',
    [Country.Guadeloupe]: 'Guadeloupe',
    [Country.EquatorialGuinea]: 'Equatorial Guinea',
    [Country.Greece]: 'Greece',
    [Country.SouthGeorgiaAndSandwichIsl]: 'South Georgia and the South Sandwich Islands',
    [Country.Guatemala]: 'Guatemala',
    [Country.Guam]: 'Guam',
    [Country.GuineaBissau]: 'Guinea-Bissau',
    [Country.Guyana]: 'Guyana',
    [Country.HongKong]: 'Hong Kong',
    [Country.HeardIslandMcdonaldIslands]: 'Heard Island and McDonald Islands',
    [Country.Honduras]: 'Honduras',
    [Country.Croatia]: 'Croatia',
    [Country.Haiti]: 'Haiti',
    [Country.Hungary]: 'Hungary',
    [Country.Indonesia]: 'Indonesia',
    [Country.Ireland]: 'Ireland',
    [Country.Israel]: 'Israel',
    [Country.IsleOfMan]: 'Isle of Man',
    [Country.India]: 'India',
    [Country.BritishIndianOceanTerritory]: 'British Indian Ocean Territory',
    [Country.Iraq]: 'Iraq',
    [Country.Iran]: 'Iran, Islamic Republic of',
    [Country.Iceland]: 'Iceland',
    [Country.Italy]: 'Italy',
    [Country.Jersey]: 'Jersey',
    [Country.Jamaica]: 'Jamaica',
    [Country.Jordan]: 'Jordan',
    [Country.Japan]: 'Japan',
    [Country.Kenya]: 'Kenya',
    [Country.Kyrgyzstan]: 'Kyrgyzstan',
    [Country.Cambodia]: 'Cambodia',
    [Country.Kiribati]: 'Kiribati',
    [Country.Comoros]: 'Comoros',
    [Country.SaintKittsAndNevis]: 'Saint Kitts and Nevis',
    [Country.KoreaDemocraticPeoplesRepublic]: 'Korea, Democratic People\'s Republic of',
    [Country.Korea]: 'Korea, Republic of',
    [Country.Kuwait]: 'Kuwait',
    [Country.CaymanIslands]: 'Cayman Islands',
    [Country.Kazakhstan]: 'Kazakhstan',
    [Country.LaoPeoplesDemocraticRepublic]: 'Lao People\'s Democratic Republic',
    [Country.Lebanon]: 'Lebanon',
    [Country.SaintLucia]: 'Saint Lucia',
    [Country.Liechtenstein]: 'Liechtenstein',
    [Country.SriLanka]: 'Sri Lanka',
    [Country.Liberia]: 'Liberia',
    [Country.Lesotho]: 'Lesotho',
    [Country.Lithuania]: 'Lithuania',
    [Country.Luxembourg]: 'Luxembourg',
    [Country.Latvia]: 'Latvia',
    [Country.LibyanArabJamahiriya]: 'Libya',
    [Country.Morocco]: 'Morocco',
    [Country.Monaco]: 'Monaco',
    [Country.Moldova]: 'Moldova, Republic of',
    [Country.Montenegro]: 'Montenegro',
    [Country.SaintMartin]: 'Saint Martin (French part)',
    [Country.Madagascar]: 'Madagascar',
    [Country.MarshallIslands]: 'Marshall Islands',
    [Country.Macedonia]: 'Macedonia, the Former Yugoslav Republic of',
    [Country.Mali]: 'Mali',
    [Country.Myanmar]: 'Myanmar',
    [Country.Mongolia]: 'Mongolia',
    [Country.Macao]: 'Macao',
    [Country.NorthernMarianaIslands]: 'Northern Mariana Islands',
    [Country.Martinique]: 'Martinique',
    [Country.Mauritania]: 'Mauritania',
    [Country.Montserrat]: 'Montserrat',
    [Country.Malta]: 'Malta',
    [Country.Mauritius]: 'Mauritius',
    [Country.Maldives]: 'Maldives',
    [Country.Malawi]: 'Malawi',
    [Country.Mexico]: 'Mexico',
    [Country.Malaysia]: 'Malaysia',
    [Country.Mozambique]: 'Mozambique',
    [Country.Namibia]: 'Namibia',
    [Country.NewCaledonia]: 'New Caledonia',
    [Country.Niger]: 'Niger',
    [Country.NorfolkIsland]: 'Norfolk Island',
    [Country.Nigeria]: 'Nigeria',
    [Country.Nicaragua]: 'Nicaragua',
    [Country.Netherlands]: 'Netherlands',
    [Country.Norway]: 'Norway',
    [Country.Nepal]: 'Nepal',
    [Country.Nauru]: 'Nauru',
    [Country.Niue]: 'Niue',
    [Country.NewZealand]: 'New Zealand',
    [Country.Oman]: 'Oman',
    [Country.Panama]: 'Panama',
    [Country.Peru]: 'Peru',
    [Country.FrenchPolynesia]: 'French Polynesia',
    [Country.PapuaNewGuinea]: 'Papua New Guinea',
    [Country.Philippines]: 'Philippines',
    [Country.Pakistan]: 'Pakistan',
    [Country.Poland]: 'Poland',
    [Country.SaintPierreAndMiquelon]: 'Saint Pierre and Miquelon',
    [Country.Pitcairn]: 'Pitcairn',
    [Country.PuertoRico]: 'Puerto Rico',
    [Country.PalestinianTerritory]: 'Palestine, State of',
    [Country.Portugal]: 'Portugal',
    [Country.Palau]: 'Palau',
    [Country.Paraguay]: 'Paraguay',
    [Country.Qatar]: 'Qatar',
    [Country.Reunion]: 'Reunion',
    [Country.Romania]: 'Romania',
    [Country.Serbia]: 'Serbia',
    [Country.RussianFederation]: 'Russian Federation',
    [Country.Rwanda]: 'Rwanda',
    [Country.SaudiArabia]: 'Saudi Arabia',
    [Country.SolomonIslands]: 'Solomon Islands',
    [Country.Seychelles]: 'Seychelles',
    [Country.Sudan]: 'Sudan',
    [Country.Sweden]: 'Sweden',
    [Country.Singapore]: 'Singapore',
    [Country.SaintHelena]: 'Saint Helena',
    [Country.Slovenia]: 'Slovenia',
    [Country.SvalbardAndJanMayen]: 'Svalbard and Jan Mayen',
    [Country.Slovakia]: 'Slovakia',
    [Country.SierraLeone]: 'Sierra Leone',
    [Country.SanMarino]: 'San Marino',
    [Country.Senegal]: 'Senegal',
    [Country.Somalia]: 'Somalia',
    [Country.Suriname]: 'Suriname',
    [Country.SouthSudan]: 'South Sudan',
    [Country.SaoTomeAndPrincipe]: 'Sao Tome and Principe',
    [Country.ElSalvador]: 'El Salvador',
    [Country.SintMaarten]: 'Sint Maarten (Dutch part)',
    [Country.SyrianArabRepublic]: 'Syrian Arab Republic',
    [Country.Swaziland]: 'Swaziland',
    [Country.TurksAndCaicosIslands]: 'Turks and Caicos Islands',
    [Country.Chad]: 'Chad',
    [Country.FrenchSouthernTerritories]: 'French Southern Territories',
    [Country.Togo]: 'Togo',
    [Country.Thailand]: 'Thailand',
    [Country.Tajikistan]: 'Tajikistan',
    [Country.Tokelau]: 'Tokelau',
    [Country.TimorLeste]: 'Timor-Leste',
    [Country.Turkmenistan]: 'Turkmenistan',
    [Country.Tunisia]: 'Tunisia',
    [Country.Tonga]: 'Tonga',
    [Country.Turkey]: 'Turkey',
    [Country.TrinidadAndTobago]: 'Trinidad and Tobago',
    [Country.Tuvalu]: 'Tuvalu',
    [Country.Taiwan]: 'Taiwan, Province of China',
    [Country.Tanzania]: 'United Republic of Tanzania',
    [Country.Ukraine]: 'Ukraine',
    [Country.Uganda]: 'Uganda',
    [Country.UnitedStates]: 'United States',
    [Country.UnitedStatesOutlyingIslands]: 'United States Minor Outlying Islands',
    [Country.Uruguay]: 'Uruguay',
    [Country.Uzbekistan]: 'Uzbekistan',
    [Country.HolySeeVaticanCityState]: 'Holy See (Vatican City State)',
    [Country.SaintVincentAndGrenadines]: 'Saint Vincent and the Grenadines',
    [Country.Venezuela]: 'Venezuela',
    [Country.VirginIslandsBritish]: 'British Virgin Islands',
    [Country.VirginIslandsUS]: 'US Virgin Islands',
    [Country.Vietnam]: 'Vietnam',
    [Country.Vanuatu]: 'Vanuatu',
    [Country.WallisAndFutuna]: 'Wallis and Futuna',
    [Country.Samoa]: 'Samoa',
    [Country.Kosovo]: 'Kosovo',
    [Country.Yemen]: 'Yemen',
    [Country.Mayotte]: 'Mayotte',
    [Country.SouthAfrica]: 'South Africa',
    [Country.Zambia]: 'Zambia',
    [Country.Zimbabwe]: 'Zimbabwe',
  },
};
