

export const calculateColor = (prop:string):string => { 
  const num = parseFloat(prop);
  const rdyNum = Math.abs(num);
  if (num === 0) {
    return '';
  } else if (num > 0) {
    if (rdyNum < 10) {
      return 'Green100';
    } else if (rdyNum < 20) {
      return 'Green200';
    } else if (rdyNum < 30) {
      return 'Green300';
    } else if (rdyNum < 40) {
      return 'Green400';
    } else if (rdyNum < 50) {
      return 'Green500';
    } else if (rdyNum < 60) {
      return 'Green600';
    } else if (rdyNum < 70) {
      return 'Green700';
    } else {
      return 'Green800';
    } 
  } else {
    if (rdyNum < 10) {
      return 'Red100';
    } else if (rdyNum < 20) {
      return 'Red200';
    } else if (rdyNum < 30) {
      return 'Red300';
    } else if (rdyNum < 40) {
      return 'Red400';
    } else if (rdyNum < 50) {
      return 'Red500';
    } else if (rdyNum < 60) {
      return 'Red600';
    } else if (rdyNum < 70) {
      return 'Red700';
    } else {
      return 'Red800';
    } 
  }

};

