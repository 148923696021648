import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { OrderQuery } from '../../clients/orders/orders';
import { UserContext, CompanyContext, OrderContext } from '../../contexts';
import { cacheKeys } from '../../config';


export const useOrderItemsList = (orderId:number | undefined) => {

  const { user } = useContext(UserContext);
  const { company } = useContext(CompanyContext);

  const { data: { data }={}, isLoading, refetch } = useQuery(
    {
      queryKey: [cacheKeys.orderItems.orderItemsList,
        user?.id, 
        company?.id,
        orderId],
      queryFn: ()=>OrderQuery.OrderItemsList(100, 0, orderId),
      enabled: (!!user && !!company && !!orderId),
    },);

  return ({
    orderItems: data?.results || [],
    count: data?.count || 0,
    Loading: isLoading, 
    refetchOrderItems: refetch,
  });
};