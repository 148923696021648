import React, { useContext } from 'react';
import { Box, Button } from '@mui/material';
import { Add, Delete, Receipt } from '@mui/icons-material';
import { useNavigate } from 'react-router';

import { routes } from '../../../config';
import { MediaQueryContext } from '../../../contexts';


export const OffersButtons = () => {
  const navigate = useNavigate();
  const { isSmallerThanMd, isSmallerThanSm } = useContext(MediaQueryContext);

  return (
    <Box sx={{ display: 'flex', flexDirection: isSmallerThanSm ? 'column' : 'row' }}>
      <Button
        variant="contained"
        color="secondary"
        sx={{ 
          m: isSmallerThanMd ? 1 : 2,
          color: 'white',
          fontSize: isSmallerThanMd ? '12px' : '16px',
        }}
        startIcon={<Receipt/>}
        onClick={()=>navigate(routes.offers)}
      >
            Aktualne oferty
      </Button>
      <Button
        variant="contained"
        color="secondary"
        sx={{
          m: isSmallerThanMd ? 1 : 2,
          color: 'white',
          fontSize: isSmallerThanMd ? '12px' : '16px' }}
        startIcon={<Delete/>}
        onClick={()=>navigate(routes.deletedOffers)}
      >
            Usunięte oferty
      </Button>
      <Button
        variant="contained"
        color="secondary"
        sx={{
          m: isSmallerThanMd ? 1 : 2,
          color: 'white',
          fontSize: isSmallerThanMd ? '12px' : '16px',
        }}
        startIcon={<Add/>}
        onClick={()=>navigate(routes.addOffer)}
      >
            Dodaj ofertę
      </Button>
    </Box>
  );
};