import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';

import { CompanyQuery } from '../clients/company/company';
import { cacheKeys } from '../config';
import { UserContext } from '../contexts';


export const useCompanyList = (limit:number, offset:number = 0, params?: any) => {
  const { isLoggedIn } = useContext(UserContext);
  const { data: { data }={}, isLoading } = useQuery([cacheKeys.company.companyList, limit, offset, params], 
    ()=>CompanyQuery.CompanyList(limit, offset, params),
    {
      enabled: isLoggedIn,
    }
  );


  return ({
    data: data?.results || [],
    count: data?.count || 0,
    isLoading,
  });
};
