import { useQuery } from '@tanstack/react-query';

import { UserClient } from '../clients/user/user';
import { cacheKeys } from '../config';


type option ={
    enabled: boolean
}

const defaultOptions = {
  enabled:true,
};

export const useMe = (token:string, jwt:string | undefined ,options:option = defaultOptions) => {
  const { fetchStatus, data, isLoading, error  } = useQuery([`${cacheKeys.user.userLogin}-${jwt}`], 
    ()=> UserClient.impersonateUser(token),
    {
      enabled: options.enabled,
    },
  );
    
  return {
    user: !!jwt ? data?.data : undefined,
    isLoading,
    error,
    fetchStatus,
  };
};