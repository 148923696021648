import { Box, Typography } from '@mui/material';

import noResults from '../../../../../layouts/images/noResults.png';


const SearchingError = () => {
  return (
    <Box sx={{ display: 'flex',alignItems: 'center' ,justifyContent: 'center',flexDirection: 'column' }}>
      <Typography variant="h4" component="h2" sx={{ marginTop: '30px' }}>
            Brak wyników wyszukiwania
      </Typography>
      <img src={noResults} alt="noResults"/>
    </Box>
  );
};

export default SearchingError;
